import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { Box, Button, Divider, Notification, Text, TextInput } from "@mantine/core";
import { functions } from "../firebase";
import { useForm } from "@mantine/form";

export function AdvancedSettings({ companyId }) {
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("red");

  const form = useForm({
    initialValues: {
      receivingCompanyId: "",
      creditAmount: "",
      reference: "",
    },
    validate: (values) => ({
        receivingCompanyId: !values.receivingCompanyId ? "Receiving Company ID is required" : undefined,
        creditAmount: !values.creditAmount || values.creditAmount < 1 ? "Credit Amount is required" : undefined,
        reference: !values.reference ? "Reference is required" : undefined,
    }),
  });

  const handleTransferCredits = async ({receivingCompanyId, creditAmount, reference }) => {
    form.validate()
    const transferCredits = httpsCallable(functions, "transferCredits");

    try {
      const result = await transferCredits({
        fromCompanyId: companyId, 
        toCompanyId: receivingCompanyId,
        creditQuantity: parseInt(creditAmount, 10),
        reference: reference,
      });
      setMessageColor("green");
      setMessage(result.data.result);
    } catch (error) {
        setMessageColor("red");
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <Text size="lg" weight="semibold">
        Advanced actions
      </Text>
      <Divider my="sm" />
      <Box style={{ maxWidth: "500px", marginTop: "1em", margin: "auto" }}>
        <Text size="md" weight="semibold">
          Transfer Credits
        </Text>
        <Text size="sm" weight="italic" style={{ marginBottom: "1em" }}>
          Immediate credit transfer to another company
        </Text>
        <form onSubmit={form.onSubmit(handleTransferCredits)}form={form}>
          <TextInput
            required
            label="Receiving company ID"
            {...form.getInputProps('receivingCompanyId')}
          ></TextInput>
          <TextInput
            name="creditAmount"
            required
            label="Credit amount"
            {...form.getInputProps('creditAmount')}
            type="number"
          ></TextInput>
          <TextInput
            name="reference"
            label="Reference"
            {...form.getInputProps('reference')}
            required
          ></TextInput>
          <Button
            color="cap-navy"
            size="md"
            style={{ margin: "2em 25%", width: "50%" }}
            disabled={form.errors.length > 0}
            type="submit"
          >
            Transfer Credits
          </Button>
        </form>
      </Box>
      {message && <Notification color={messageColor} style={{maxWidth: "400px", margin: "auto"}} onClose={() => setMessage('')}>{message}</Notification>}
    </div>
  );
}
