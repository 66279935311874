import React from "react";
import { Button, Modal } from "@mantine/core";
import { Eye, FileDownload } from "tabler-icons-react";

export function DownloadAlert({
  renderType,
  reportName,
  Base64File,
  modalOpen,
  onModalClose,
  url,
}) {
  const linkSource = `data:application/${renderType};base64,${Base64File}`;
  const fileName = `report_${reportName}.${renderType}`;

  const handleView = async () => {
    try {
      const blob = await b64toBlob(Base64File, `application/${renderType}`);
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL, "_blank");
    } catch (error) {
      alert("Could not open PDF - please use any device except iPhone.");
    }
  };
  const b64toBlob = (base64, type = "application/pdf") =>
    fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

  return (
    <>
      <Modal
        opened={modalOpen}
        onClose={() => onModalClose()}
        centered
        title="Report ready"
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {url ? (
            <>
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  leftIcon={<FileDownload size={18} />}
                  color="cap-navy"
                  style={{ width: "150px" }}
                >
                  Download
                </Button>
              </a>
            </>
          ) : (
            <>
              {renderType === "pdf" && (
                <Button
                  leftIcon={<Eye size={18} />}
                  onClick={handleView}
                  color="cap-navy"
                  style={{ width: "150px" }}
                >
                  View
                </Button>
              )}
              <a href={linkSource} download={fileName}>
                <Button
                  leftIcon={<FileDownload size={18} />}
                  color="cap-navy"
                  style={{ width: "150px" }}
                >
                  Download
                </Button>
              </a>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default DownloadAlert;
