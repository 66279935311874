import React, { useEffect, useState } from "react";

import {
  Divider,
  Loader,
  Notification,
  Space,
  Text,
} from "@mantine/core";

import Pay from "../components/shared/pay";
import { InfoCircle } from "tabler-icons-react";
import BillingInfo from "../components/shared/billing-info";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export function CompanyDetails({ companyId }) {
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedCompany, setFetchedCompany] = useState(false);

  useEffect(() => {
    if (!fetchedCompany && companyId) {
      async function fetchCompany() {
        setFetchedCompany(true);
        setLoading(true);
        const docRef = doc(db, "companies", companyId);

        const documentSnapshot = await getDoc(docRef)
        if(documentSnapshot.exists) {
          setCompany(documentSnapshot.data());
        }
        setLoading(false);
      }
      fetchCompany();
    }
  }, [companyId]);

  return (
    <>
      {companyId && (
        <>
          <Divider my="sm" />
          {loading && <Loader style={{ margin: "auto", width: "100%" }}></Loader>}
          {!!company && (<>
            <Text size="lg">
              {company.name}
            </Text>
            <div style={{ display: "flex", flexDirection: "row", padding: "16px 32px" }}>
              <Text size="lg" pr="8px">
                Activation code:
              </Text>
              <Text size="lg" weight={"bold"}>
                {company.activationCode ? ` ${company.activationCode}`: "..."}
              </Text>
            </div>
            <Text size="sm" weight="italic" px={"48px"}>
              Activation code used to link more users to this company during registration
            </Text>
          </>)}
          <Divider my="sm" />
          <Pay companyId={companyId}></Pay>
          <Space h="sm" />
          <Divider my="sm" />
          <BillingInfo companyId={companyId}></BillingInfo>
          <Divider my="sm" />
          {message && (
            <Notification
              icon={<InfoCircle size={18} />}
              color="cap-blue"
              title="Info"
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                zIndex: 1000,
              }}
              onClose={() => setMessage("")}
            >
              {message}
            </Notification>
          )}
        </>
      )}
    </>
  );
}
