// export enum TripStatus {
//     PLANNED = "PLANNED",
//     ALLOCATED = "ALLOCATED",
//     PRE_INSPECTED = "PRE_INSPECTED",
//     IN_PROGRESS = "IN_PROGRESS",
//     ARRIVED = "ARRIVED",
//     COMPLETED = "COMPLETED",
//   }
export const TripConfig = {    
    description: {type: "STRING", required: true, editable: true, label: "Description", rank: 0},
    status: {type: "STRING", required: true, editable: false, label: "Status", rank: 1},
    pickupAddress: {type: "STRING", required: true, editable: true, label: "Pickup address", rank: 2},
    pickupDate: {type: "DATE", required: true, editable: true, label: "Pickup date", rank: 3},
    deliveryAddress: {type: "STRING", required: true, editable: true, label: "Delivery address", rank: 4},
    deliveryDate: {type: "DATE", required: true, editable: true, label: "Delivery date", rank: 5},
    fleetId: {type: "SELECT", required: false, editable: true, label: "Vehicle", rank: 6},
    fleetOperatorId: {type: "SELECT", required: false, editable: true, label: "Driver", rank: 7},
    requiresPreInspection: {type: "BOOLEAN", required: false, editable: true, label: "Requires pre-inspection", rank: 8},
    notificationEmailAddresses: {type: "STRING_ARRAY", required: false, editable: true, label: "Notification emails", rank: 9},
    externalReference: {type: "STRING", required: false, editable: true, label: "External reference", rank: 10},
    notes: {type: "STRING", required: false, editable: true, label: "Notes", rank: 11},
    // creatorEmail: {type: "STRING", required: false, editable: false, label: "Creator email", rank: 10},
    // fleetGroupName: {type: "STRING", required: false, editable: false, label: "fleetGroupName", rank: 10},
    // fleetDescription: {type: "STRING", required: false, editable: false, label: "fleetDescription", rank: 10},
    // fleetOdoOrHourBased: {type: "STRING", required: false, editable: false, label: "fleetOdoOrHourBased", rank: 10},
    // fleetGroupId: {type: "STRING", required: false, editable: false, label: "fleetGroupId", rank: 10},
    // fleetRegistration: {type: "STRING", required: false, editable: false, label: "fleetRegistration", rank: 10},
    // fleetDisplayName: {type: "STRING", required: false, editable: false, label: "fleetDisplayName", rank: 10},
    // fleetUseFleetForRebateClaim: {type: "STRING", required: false, editable: false, label: "fleetUseFleetForRebateClaim", rank: 10},
    // driverNameAndSurname: {type: "STRING", required: false, editable: false, label: "driverNameAndSurname", rank: 10},
    // driverEmployeeCode: {type: "STRING", required: false, editable: false, label: "driverEmployeeCode", rank: 10},
    // driverIdentification: {type: "STRING", required: false, editable: false, label: "driverIdentification", rank: 10},
    // companyId: {type: "STRING", required: false, editable: false, label: "companyId", rank: 10},
    // isTrackingActive: {type: "STRING", required: false, editable: false, label: "isTrackingActive", rank: 10},
    // creatorUid: {type: "STRING", required: false, editable: false, label: "creatorUid", rank: 10},
    // locationSnapshots: {type: "STRING", required: false, editable: false, label: "locationSnapshots", rank: 1},
    // capturedTripArrive: {type: "STRING", required: false, editable: false, label: "capturedTripArrive", rank: 1},
    // capturedTripPreInspection: {type: "STRING", required: false, editable: false, label: "capturedTripPreInspection", rank: 1},
    // capturedTripDepart: {type: "STRING", required: false, editable: false, label: "capturedTripDepart", rank: 1},
    // capturedTripCheckPoint: {type: "STRING", required: false, editable: false, label: "capturedTripCheckPoint", rank: 1},
    // capturedTripComplete: {type: "STRING", required: false, editable: false, label: "capturedTripComplete", rank: 1},
    // capturedTripDelay: {type: "STRING", required: false, editable: false, label: "capturedTripDelay", rank: 1},
};

// export interface Trip {
//     id: string;
//     status: TripStatus;
//     requiresPreInspection: boolean;
//     locationSnapshots: any[];
//     companyId: string;
//     capturedTripPreInspection: Array<string>;
//     capturedTripDepart: Array<string>;
//     capturedTripCheckPoint: Array<string>;
//     capturedTripDelay: Array<string>;
//     capturedTripArrive: Array<string>;
//     capturedTripComplete: Array<string>;
//     isTrackingActive: boolean;
//     tripToken: string;
//     pickupAddress: string;
//     deliveryAddress: string;
//     pickupDate: string;
//     deliveryDate: string;
//     description: string;
//     creatorUid: string;
//     creatorEmail: string;
//     externalReference?: string;
//     notes?: string;
//     fleetId?: string;
//     isOutOfSync?: boolean;
//     notificationEmailAddresses?: string[];
//     fleetDescription?: string;
//     fleetDisplayName?: string;
//     fleetRegistration?: string;
//     fleetOdoOrHourBased?: string;
//     fleetUseFleetForRebateClaim?: boolean;
//     fleetGroupId?: string;
//     fleetGroupName?: string;
//     fleetOperatorId?: string;
//     fleetOperatorEmployeeCode?: string;
//     fleetOperatorIdentification?: string;
//     fleetOperatorNameAndSurname?: string;
//     // auth uid of the user that started the trip
//     // they now 'own' the trip through to the end
//     tripOwnerUid?: string;
//   }
