import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  AppShell,
  Navbar,
  Header,
  Text,
  useMantineTheme,
  Button,
  Image,
  Loader,
  ThemeIcon,
  UnstyledButton,
  Group,
  MediaQuery,
  Burger,
  Space,
  Divider,
  ScrollArea,
} from "@mantine/core";

import { logout } from "../firebase";

import "./app.css";
import {
  ArrowsTransferUp,
  Book,
  Building,
  Eye,
  Mail,
  PageBreak,
  Recharging,
  Route,
  Truck,
  User,
  GasStation,
  ArrowRightCircle,
  Users,
  Bolt,
} from "tabler-icons-react";

function MainLink({
  icon,
  color,
  label,
  target,
  selected,
  clickHandler,
  variant,
}) {
  return (
    <UnstyledButton
      disabled
      component={Link}
      to={target}
      onClick={() => clickHandler()}
      sx={(theme) => ({
        display: "block",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[0]
            : theme.colors["cap-navy"],
        border: selected ? "0.5px solid" : "0.5px solid transparent",
        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant={variant}>
          {icon}
        </ThemeIcon>
        <Text size="md">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}

const TemplateLinksData = [
  {
    icon: <GasStation />,
    label: "Roadside refuels",
    color: "cap-orange",
    variant: "filled",
    target: "/dashboard/capturedRefuel",
  },
  {
    icon: <Eye />,
    label: "Tank readings",
    target: "/dashboard/capturedTankLevelReading",
    color: "cap-navy",
    variant: "filled",
  },
  {
    icon: <ArrowsTransferUp />,
    label: "Fuel transfers (tanks)",
    target: "/dashboard/capturedFuelTankTransfer",
    color: "cap-blue",
    variant: "filled",
  },
  {
    icon: <ArrowRightCircle />,
    label: "Onsite refuels",
    target: "/dashboard/capturedFuelTankIssue",
    color: "cap-purple",
    variant: "filled",
  },
  {
    icon: <Recharging />,
    label: "Bulk deliveries",
    color: "yellow",
    target: "/dashboard/capturedBulkFuelReceived",
    variant: "filled",
  },
];

export function TemplateLinks({ selectedPath, setBurger }) {
  const links = TemplateLinksData.map((link) => (
    <MainLink
      {...link}
      key={link.label}
      selected={selectedPath === link.target}
      clickHandler={() => setBurger(false)}
    />
  ));
  return <div>{links}</div>;
}

function ShellWithNavigation({ user, children }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const currentPath = window.location.pathname;

  return (
    <AppShell
      styles={{
        main: {
          background: "white",
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 300, lg: 300 }}
          styles={{ overflowY: "scroll", color: theme.colors["cap-navy"] }}
        >
          <ScrollArea scrollbars="y">
            <Navbar.Section>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "6px 0",
                }}
              >
                <Image
                  src="../../captiva-fuel-icon.png"
                  height={40}
                  width={40}
                  mr={10}
                ></Image>
                <Text size="lg" weight={400}>
                  Captiva Fuel
                </Text>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <TemplateLinks
                  style={{ paddingLeft: "10px" }}
                  selectedPath={currentPath}
                  setBurger={setOpened}
                />
              </div>
            </Navbar.Section>
            <Navbar.Section>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "6px 0",
                }}
              >
                <Image
                  src="../../captiva-trip-icon.png"
                  height={40}
                  width={40}
                  mr={10}
                ></Image>
                <Text size="lg" weight={400}>
                  Captiva Trip
                </Text>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <MainLink
                  icon={<Route />}
                  label="Trips"
                  target="/dashboard/trips"
                  color="cap-navy"
                  variant="filled"
                  selected={currentPath === "/dashboard/trips"}
                  clickHandler={() => setOpened(false)}
                />
              </div>
            </Navbar.Section>
            <Divider></Divider>
            <Space h="sm" />
            <Navbar.Section>
              <Text size="lg" weight={400}>
                Settings
              </Text>
              <MainLink
                icon={<Truck />}
                label="Company assets"
                target="/dashboard/assets"
                selected={currentPath === "/dashboard/assets"}
                color="cap-blue"
                variant="light"
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<Building />}
                label="Company overview"
                target="/dashboard/settings"
                color="cap-blue"
                variant="light"
                selected={currentPath === "/dashboard/settings"}
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<Users />}
                label="Users"
                target="/dashboard/users"
                color="cap-blue"
                variant="light"
                selected={currentPath === "/dashboard/users"}
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<Mail />}
                label="Notifications"
                target="/dashboard/notifications"
                selected={currentPath === "/dashboard/notifications"}
                color="cap-blue"
                variant="light"
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<PageBreak />}
                label="Reports"
                color="cap-blue"
                variant="light"
                target="/dashboard/reports"
                selected={currentPath === "/dashboard/reports"}
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<Book />}
                label="Advanced reports"
                target="/dashboard/advanced-reports"
                color="cap-blue"
                variant="light"
                selected={currentPath === "/dashboard/advanced-resports"}
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<User />}
                label="Account"
                target="/dashboard/account"
                color="cap-blue"
                variant="light"
                selected={currentPath === "/dashboard/account"}
                clickHandler={() => setOpened(false)}
              />
              <MainLink
                icon={<Bolt />}
                label="Advanced actions"
                target="/dashboard/advanced-settings"
                color="cap-blue"
                variant="light"
                selected={currentPath === "/dashboard/advanced-settings"}
                clickHandler={() => setOpened(false)}
              />
            </Navbar.Section>
          </ScrollArea>
        </Navbar>
      }
      header={
        <Header height={70} p="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="md"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div style={{ height: "2em" }}>
              <Image
                radius="md"
                fit="contain"
                src="../../captiva-logo.png"
                style={{ width: "9em", objectFit: "contain" }}
              />
            </div>
            {/* <Text
              component="span"
              align="center"
              // variant="gradient"
              // gradient={{ from: "indigo", to: "red", deg: 45 }}
              size="m"
              weight={700}
              style={{
                color: "#0339A6",
                width: "8em",
              }}
            >
              FUEL SENSE
            </Text> */}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Text>{user?.email}</Text> */}
              <Button
                onClick={logout}
                color="cap-navy"
                style={{ marginLeft: "1em" }}
              >
                Logout
              </Button>
            </div>
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}

export function AppShellResponsive({ children, loading, user }) {
  return (
    <>
      {loading ?? (
        <div class={{}}>
          <Loader color="#cc185f" size="lg" variant="bars" />
        </div>
      )}
      {user ? (
        <ShellWithNavigation user={user}>{children}</ShellWithNavigation>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
