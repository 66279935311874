import React, { Fragment, useState } from "react";

import { Button, MediaQuery, Modal, Table } from "@mantine/core";

import { QrDisplay } from "../../components/shared/qr-display";
import { FleetOperatorConfig } from "../../components/listings/configurations/assets/fleet-operator-config";
import { AssetEditor } from "../../components/shared/asset-editor";
import { Plus, Qrcode } from "tabler-icons-react";

export function VehicleOperatorsTable({ elements, auth, reloadCallback }) {
  const newDriver = {
    employeeCode: "",
    fullName: "",
    identification: "",
    nickName: "",
  };
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [editedElement, setEditedElement] = useState(undefined);
  const closeEdit = () => setEditedElement(undefined);
  const EditButton = ({ element }) => (
    <Button
      color={"cap-navy"}
      variant="outline"
      onClick={() => {
        setEditedElement(element);
      }}
    >
      Edit
    </Button>
  );
  const rows = elements.map((element) => (
    <Fragment key={element.id}>
      {/* // desktop sizes */}
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element.fullName}</td>
          <td>{element.employeeCode}</td>
          <td>{element.nickName}</td>
          <td>
            <Button
              color={"cap-navy"}
              variant="outline"
              onClick={() => {
                setQrLabel(element.fullName);
                setQrValue(element.id);
                setIsExpanded(true);
              }}
              leftIcon={<Qrcode />}
            >
              Print QR
            </Button>
          </td>
          <td>
            <EditButton element={element} />
          </td>
        </tr>
      </MediaQuery>
      {/* // phone sizes */}
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element.fullName}</td>
          <td>{element.employeeCode}</td>
          <td>
            <EditButton element={element} />
          </td>
        </tr>
      </MediaQuery>
    </Fragment>
  ));

  return (
    <>
      <Table width={"100%"}>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Employee code</th>
              <th>Nickname</th>
              <th>Asset QR</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Employee code</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <tbody>{rows}</tbody>
        <Modal
          opened={isExpanded}
          onClose={() => setIsExpanded(false)}
          closeOnClickOutside
        >
          <QrDisplay
            qrData={qrValue}
            qrLabel={qrLabel}
            assetName={"Operator"}
          />
        </Modal>
      </Table>
      {editedElement && (
        <Modal opened onClose={closeEdit} title="Fleet operator">
          <AssetEditor
            asset={editedElement}
            assetConfig={FleetOperatorConfig}
            collection="fleetOperators"
            auth={auth}
            reloadCallback={() => {
              reloadCallback();
              closeEdit();
            }}
          ></AssetEditor>
        </Modal>
      )}
      <Button
        onClick={() => setEditedElement(newDriver)}
        fullWidth
        my="xl"
        color="cap-navy"
        variant="outline"
        leftIcon={<Plus />}
      >
        Create new
      </Button>
    </>
  );
}