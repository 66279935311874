import React, { useCallback, useEffect } from "react";
import { Button, Card, Divider, Image, Loader, Space, Text } from "@mantine/core";
import { useState } from "react";
import {
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import { TripTable } from "./tables/trip-table";
import {
  getWeekOldDate,
  useHandleQueryResult,
  useResetListingState,
} from "../components/listings/hooks";
import { DateRangePicker } from "@mantine/dates";
import { useTripBuildQuery } from "../components/listings/hooks/use-listing-query";

export function Trips({ auth, companyId }) {
  //
  const [flagsFetched, setFlagsFetched] = useState(false);
  const [isTripsEnabled, setIsTripsEnabled] = useState(false);
  const [trips, setTrips] = useState([]);
  const [doneInitialFetch, setInitialFetched] = useState(false);
  const [lastVisibleDoc, setLastVisibleDoc] = useState();
  const [paginationDone, setPaginationDone] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showTooltip, setShowTooltip] = useState(false);

  const [dates, setDates] = useState([getWeekOldDate(1), new Date()]);

  const pageSize = 20;

  const buildQuery = useTripBuildQuery({
    db,
    companyId,
    pageSize,
    collectionName: "trips",
  });

  const handleResults = useHandleQueryResult({
    pageSize,
    setDocuments: setTrips,
    setLastVisibleDoc,
    setPaginationDone,
  });

  const reset = useResetListingState({
    setDocuments: setTrips,
    setInitialFetched,
    setLastVisibleDoc,
    setPaginationDone,
  });

  // reset listing if dates changed
  useEffect(() => {
    if (dates[0] && dates[1]) {
      reset();
    }
  }, [dates]);

  // load more entries / documents
  const loadMoreEntries = useCallback(async () => {
    if (loading) return;

    setInitialFetched(true);
    setLoading(true);

    const documentSnapshots = await getDocs(buildQuery(lastVisibleDoc, dates));

    handleResults(documentSnapshots);

    setLoading(false);
  }, [loading, lastVisibleDoc, buildQuery, dates, handleResults]);

  // TODO - fix double trigger, only visible locally using Strict Mode
  useEffect(() => {
    if (!doneInitialFetch && companyId) {
      loadMoreEntries();
    }
  }, [companyId, doneInitialFetch]);

  useEffect(() => {
    if (!flagsFetched && companyId) {
      async function fetchFlags() {
        const documentSnapshot = await getDoc(
          doc(db, "featureFlags", "hA0T8o3RipWMnJQDnw4p")
        );

        if (documentSnapshot.data().trips.includes(companyId)) {
          setIsTripsEnabled(true);
        }
        setFlagsFetched(true);
      }
      fetchFlags();
    }
  }, [companyId]);


  const handleTripUpdate = (trip, isDelete) => {
    // create trip
    if(trip && trips.findIndex(t => t.id === trip.id) === -1) {
      setTrips([...trips, trip].sort((a, b) => b.pickupDate - a.pickupDate));
    } else {
      // update
      setTrips(trips.map(t => t.id === trip.id ? trip : t));
    };

    if(isDelete) {
      setTrips(trips.filter(t => t.id !== trip.id));
    }
  };

  return (
    <>
      {!flagsFetched ? (
        <Loader />
      ) : (
        <>
          {isTripsEnabled ? (
            <>
              <Text size="xl" weight="semibold">
                Trips
              </Text>
              <div style={{ display: "inline-flex" }}>
                <DateRangePicker
                  placeholder="Pickup date range"
                  amountOfMonths={1}
                  style={{ width: "12em" }}
                  inputFormat="MM/DD/YYYY"
                  clearable={false}
                  value={dates}
                  onChange={setDates}
                />
                {/* MIGHT BE FUTURE FUNCTIONALITY */}
                {/* <Tooltip
                  label="Download current list as CSV"
                  opened={showTooltip}
                  withArrow
                >
                  <Button color="cap-navy"
                    variant="default"
                    size="md"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    <CSVLink data={documents}>Download</CSVLink>
                  </Button>
                </Tooltip> */}
              </div>
              <Space h="sm" />
              <Divider />
              <Space h="sm" />
              <TripTable
                elements={trips}
                auth={auth}
                companyId={companyId}
                reloadCallback={(tripId, isDelete) => handleTripUpdate(tripId, isDelete)}
              />
              {!paginationDone && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2em",
                  }}
                >
                  {loading ? (
                    <Loader color="cap-navy" style={{ margin: "auto" }} />
                  ) : (
                    <Button
                      color="cap-navy"
                      style={{ padding: "0 20%" }}
                      onClick={loadMoreEntries}
                      disabled={loading}
                    >
                      {trips.length ? "Load more entries" : "Load recent entries"}
                    </Button>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <Card withBorder>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Image
                    src="../../captiva-trip-logo.png"
                    width={300}
                    my={20}
                  ></Image>
                  <Text size="xl" weight="semibold">
                    Request early access to Captiva Trip via{" "}
                    <a href="mailto:admin@captivadata.com">email</a>
                  </Text>
                  <video
                    controls
                    width="339"
                    height="852"
                    src="https://firebasestorage.googleapis.com/v0/b/twodata-dev.appspot.com/o/marketing%2FTrips-Demo.mov?alt=media&token=a0a9007c-cd05-424b-b4c4-2305ce961525"
                  />
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Trips;
