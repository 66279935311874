import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { AppRoot } from "./app-root";

import "./index.css";

const container = document.getElementById("app");

const root = ReactDOMClient.createRoot(container);

root.render(
  <Router>
    <AppRoot />
  </Router>
  // <React.StrictMode>
  // </React.StrictMode>
);
