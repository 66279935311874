import React, { useState, Fragment } from "react";

import { Button, Modal, Table, Alert, Grid, Text, MediaQuery } from "@mantine/core";

import { useForm } from "@mantine/form";
import { formatValue } from "../../components/listings/hooks";
import { DataEntry } from "../../components/shared/data-entry";
import { updateDoc, doc, deleteDoc, collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { SectionAssetConfig } from "../../components/listings/configurations/assets/section-asset-confing";
import { ZoneAssetConfig } from "../../components/listings/configurations/assets/zone-asset-config";
import { Edit, Plus } from "tabler-icons-react";

export function ZoneEditor({
  asset,
  assetConfig,
  saveAssetCallback,
  deleteAssetCallback,
}) {
  const [initialValues] = useState({ ...asset });
  const [deleteCandidate, setDeleteCandidate] = useState(undefined);
  function buildValidationRules(assetConfig) {
    let validationRulesConfig = {};
    Object.keys(assetConfig).forEach((key) => {
      // check if required (ignore booleans)
      if (assetConfig[key].required && assetConfig[key].type !== "BOOLEAN") {
        validationRulesConfig[key] = (value) => (!value ? "Required" : null);
      }
      // right now all numbers are required check if positive number
      // todo future: handle non-required number case
      if (assetConfig[key].type === "NUMBER") {
        validationRulesConfig[key] = (value) =>
          isNaN(Number(value)) || Number(value) < 0
            ? "Must be a positive number"
            : null;
      }
    });
    return validationRulesConfig;
  }

  const form = useForm({
    initialValues,
    validate: buildValidationRules(assetConfig),
  });

  async function saveAsset(updatedAsset) {
    saveAssetCallback(updatedAsset)
  }

  async function deleteAsset(deleteCandidate) {
    deleteAssetCallback(deleteCandidate)
  }

  return (
    <>
      <form onSubmit={form.onSubmit((values) => saveAsset(values))}>
      {Object.keys(initialValues)
          .filter((k) => assetConfig[k])
          .sort((a, b) => assetConfig[a].rank - assetConfig[b].rank)
          .map((key) => (
            <div
              key={key}
              style={{
                marginTop: "1em",
              }}
            >
              <DataEntry
                key={key}
                label={assetConfig[key]?.label}
                edit={assetConfig[key]?.editable}
                inputProps={form.getInputProps(key)}
                dataType={assetConfig[key]?.type}
                form={form}
                fieldKey={key}
                value={formatValue(assetConfig[key]?.type, asset[key])}
              />
            </div>
          ))}
        <Button size="md" color="cap-navy" fullWidth type="submit" mt="md">
          Save
        </Button>
      </form>
      {asset.id && (
        <>
          {!!deleteCandidate ? (
              <Grid my="md">
                <Grid.Col span={4} styles={{display: "flex", alignItems:"center"}}>
                  <Text size="lg">Are you sure?</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button
                    color={"cap-navy"}
                    fullWidth
                    onClick={() => setDeleteCandidate(undefined)}
                  >
                    Cancel
                  </Button>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button color="red" onClick={() => deleteAsset(asset)} fullWidth>
                    Yes - Delete
                  </Button>
                </Grid.Col>
              </Grid>
          ) : (
            <Button
              size="md"
              color="red"
              fullWidth
              mt="md"
              onClick={() => setDeleteCandidate(asset)}
            >
              Delete
            </Button>
          )}
        </>
      )}
    </>
  );
}

export function ZonesTable({ elements, auth, reloadCallback }) {
  const newSection = {
    sectionName: "",
    isActive: true,
    areaSizeHectares: 0,
  };

  const newZone = {
    zoneName: "",
    sections: [],
    isActive: true,
  };

  const [editedZone, setEditedZone] = useState(undefined);
  const [editedSection, setEditedSection] = useState(undefined);
  const [editedSectionZone, setEditedSectionZone] = useState(undefined);
  const [editedSectionIndex, setEditedSectionIndex] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [messageColor, setMessageColor] = useState('green');
  const closeZoneEdit = () => setEditedZone(undefined);
  const closeSectionEdit = () => {setEditedSection(undefined); setEditedSectionIndex(undefined)};
  const targetCollection = 'areaForFleetUse';

  const createTimedMessage = (message) => {
    setMessage(message);
    setTimeout(() => setMessage(""), 2000)
  }

  const finishEditWithMessage = (message) => {
    setMessageColor("green")
    createTimedMessage(message);
    closeZoneEdit()
    closeSectionEdit()
    reloadCallback();
  }

  const finishEditWithErrorMessage = (message) => {
    setMessageColor("red")
    createTimedMessage(message);
    closeZoneEdit()
    closeSectionEdit()
    reloadCallback();
  }

  const updateZone = async(updatedZone) => {
    if (!targetCollection || !auth || !updatedZone) {
      finishEditWithErrorMessage("Save unsuccessful.");
    }
     // create
     const docRef = doc(db, targetCollection, updatedZone.id);
     await updateDoc(docRef, updatedZone);

     finishEditWithMessage("Successfully saved!");
  }

  const createZone = async(newZone) => {
    if (!targetCollection || !auth || !newZone) {
      finishEditWithErrorMessage("Save unsuccessful.");
    }
    // create
    await addDoc(collection(db, targetCollection), {
      ...newZone,
      companyId: auth.claims.companyId,
    });
    finishEditWithMessage("Successfully created!");
 }


  // can only update zone name and active
  const onSaveZone = async (updatedZone) => {
    const mergedZone = {...editedZone, ...updatedZone};
    if(mergedZone.id) {
      // edit existing zone
      await updateZone(mergedZone);
    } else {
      // create
      await createZone(mergedZone);
    }
  }

  const handleDeleteZone = async (deletionCandidateZone) => {
    try {
      const docRef = doc(db, targetCollection, deletionCandidateZone.id);
      await deleteDoc(docRef);
      finishEditWithMessage("Delete successful.");
    } catch (error) {
      setMessageColor("red")
      finishEditWithErrorMessage("Delete unsuccessful.", error.toString());
    }
  }
  const handleSaveSection = async(sectionCandidate) => {
    const updatedZone = {...editedSectionZone};
    if(editedSectionIndex >= 0) {
      // edit existing section & save zone
      const updatedSection = {...editedSection, ...sectionCandidate};
      updatedZone.sections[editedSectionIndex] = updatedSection;
      await onSaveZone(updatedZone)
      finishEditWithMessage("Successfully updated!");
    } else {
      // add new section to end of sections list
      const newSection = {...sectionCandidate, id: `${updatedZone} - ${sectionCandidate.name}`};
      updatedZone.sections.push(newSection);
      await onSaveZone(updatedZone)
      finishEditWithMessage("Successfully created!");
    }
  }

  const handleDeleteSection = async(deletionCandidateSection) => {
    const indexToRemove = editedSectionZone.sections.findIndex(s => s.id === deletionCandidateSection.id);
    const updatedZone = {...editedSectionZone};
    updatedZone.sections.splice(indexToRemove, 1);
    await onSaveZone(updatedZone);
    finishEditWithMessage("Successfully updated!");
  }

  const SectionList = ({element}) =>
  <td>
    {element.sections?.length ? (
      <>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Table>
            <thead>
              <tr>
                <th>Section name</th>
              </tr>
            </thead>
            <tbody>
              {element.sections?.map((section, index) => (
                <tr key={section.id}>
                  <td>{section.sectionName}</td>
                  <td>
                    <Button
                      color={"cap-navy"}
                      variant="outline"
                      leftIcon={<Edit />}
                      onClick={() => {
                        setEditedSection(section);
                        setEditedSectionZone(element);
                        setEditedSectionIndex(index);
                      }}
                    >
                      Section
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </MediaQuery>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Table>
            <thead>
              <tr>
                <th>Section name</th>
                <th>Section size</th>
              </tr>
            </thead>
            <tbody>
              {element.sections?.map((section, index) => (
                <tr key={section.id}>
                  <td>{section.sectionName}</td>
                  <td>{section.areaSizeHectares}</td>
                  <td>
                    <Button
                      color={"cap-navy"}
                      variant="outline"
                      leftIcon={<Edit />}
                      onClick={() => {
                        setEditedSection(section);
                        setEditedSectionZone(element);
                        setEditedSectionIndex(index);
                      }}
                    >
                      Section
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </MediaQuery>
      </>
    ) : (
      <>No sections</>
    )}
    <Button onClick={() => {setEditedSection(newSection); setEditedSectionIndex(-1); setEditedSectionZone(element);}}  fullWidth my="xl" color="cap-navy" variant="outline" leftIcon={<Plus />}>
      New section
    </Button>
  </td>

  const rows = elements.map((element) => (
    <Fragment key={element.id}>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element.zoneName}</td>
          <SectionList element={element} />
          <td>{element.isActive.toString()}</td>
          <td>
            <Button
              color={"cap-navy"}
              variant="outline"
              onClick={() => {
                setEditedZone(element);
              }}
            >
              Edit zone
            </Button>
          </td>
        </tr>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element.zoneName}</td>
          <SectionList element={element} />
          <td>
          <Button
              color={"cap-navy"}
              variant="outline"
              onClick={() => {
                setEditedZone(element);
              }}
            >
              Edit
            </Button>
          </td>
        </tr>
      </MediaQuery>
    </Fragment>
  ));

  return (
    <>
      {message && <Alert color={messageColor} onAnimationEnd={() => setTimeout(() => setMessage(undefined), 500)}>{message}</Alert>}
      <Table width={"100%"}>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Zone name</th>
              <th>Sections</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Zone name</th>
              <th>Sections</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <tbody>{rows}</tbody>
      </Table>
      {editedZone && (
        <Modal opened onClose={closeZoneEdit} title="Zone">
          <ZoneEditor
            asset={editedZone}
            assetConfig={ZoneAssetConfig}
            auth={auth}
            saveAssetCallback={onSaveZone}
            deleteAssetCallback={handleDeleteZone}
          ></ZoneEditor>
        </Modal>
      )}
      {editedSection && (
        <Modal opened onClose={closeSectionEdit} title="Section">
          <ZoneEditor
            asset={editedSection}
            assetConfig={SectionAssetConfig}
            auth={auth}
            saveAssetCallback={handleSaveSection}
            deleteAssetCallback={handleDeleteSection}
          ></ZoneEditor>
        </Modal>
      )}
      <Button onClick={() => setEditedZone(newZone)}  fullWidth my="xl" color="cap-navy" variant="outline" leftIcon={<Plus />}>
        Create new zone
      </Button>
    </>
  );
}
