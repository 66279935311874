export const RefuelConfig = {
    reportConfig: {
      reportId: "jfN53aWEpCBoxVzouErd",
    },
    fieldConfig: {
      deviceCaptured: {label: "Device Captured",tableRank: -1, detailsRank: -1, editable: false, type: "DATE"},
      gpsLocation: {label: "Location",tableRank: -1, detailsRank: 3, editable: false, type: "COORDINATE"},
      transactionDate: {label: "Date",tableRank: 1, detailsRank: 0, editable: true, type: "DATE"},
      driverOperator: {label: "Fleet operator",tableRank: -1, detailsRank: 1, editable: false, type: "STRING"},
      driverOperatorSignedImage: {label: "Operator signature",tableRank: -1, detailsRank: 1, editable: false, type: "IMAGE"},
      pumpPhoto: {label: "Operator signature",tableRank: -1, detailsRank: 1, editable: false, type: "IMAGE"},
      slipPhoto: {label: "Receipt photo",tableRank: -1, detailsRank: 1, editable: false, type: "IMAGE"},
      litres: {label: "Litres",tableRank: 2, detailsRank: 1, editable: true, type: "NUMBER"},
      activityName: {label: "Activity",tableRank: -1, detailsRank: 4, editable: false, type: "STRING"},
      activityRebateEligiable: {label: "Rebate eligible",tableRank: -1, detailsRank: 4, editable: false, type: "STRING"},
      areaForFleetUseFarmName: {label: "Area name",tableRank: -1, detailsRank: 7, editable: false, type: "STRING"},
      areaForFleetUseSizeHectares: {label: "Area size",tableRank: -1, detailsRank: 7, editable: false, type: "STRING"},
      areaforFleetUseName: {label: "Section name",tableRank: -1, detailsRank: 8, editable: false, type: "STRING"},
      driverEmployeeCode: {label: "Operator employee code",tableRank: -1, detailsRank: 1, editable: false, type: "STRING"},
      driverIdentification: {label: "Operator ID",tableRank: -1, detailsRank: 1, editable: false, type: "STRING"},
      driverNameAndSurname: {label: "Operator name",tableRank: -1, detailsRank: 1, editable: false, type: "STRING"},
      driverNickName: {label: "Operator nickname",tableRank: -1, detailsRank: 1, editable: false, type: "STRING"},
      odoOrHourReadingPhoto: {label: "ODO photo",tableRank: -1, detailsRank: 1, editable: false, type: "IMAGE"},
      odoOrHourReading: {label: "ODO",tableRank: 4, detailsRank: 1, editable: true, type: "NUMBER"},
      productName: {label: "Product",tableRank: -1, detailsRank: 5, editable: false, type: "STRING"},
      fleetDefaultActivity: {label: "FleetDefaultActivity",tableRank: -1, detailsRank: -1, editable: false, type: "STRING"},
      fleetDescription: {label: "FleetDescription",tableRank: -1, detailsRank: -1, editable: false, type: "STRING"},
      fleetDisplayName: {label: "Vehicle",tableRank: 2, detailsRank: 1, editable: false, type: "STRING"},
      fleetGroupName: {label: "Vehicle group",tableRank: -1, detailsRank: 1, editable: false, type: "STRING"},
      fleetOdoOrHourBased: {label: "FleetOdoOrHourBased",tableRank: -1, detailsRank: -1, editable: false, type: "STRING"},
      fleetPreviousFillingDate: {label: "FleetPreviousFillingDate",tableRank: -1, detailsRank: -1, editable: false, type: "STRING"},
      fleetRegistration: {label: "Vehicle registration",tableRank: 5, detailsRank: 1, editable: false, type: "STRING"},
      fleetUseFleetForRebateClaim: {label: "FleetUseFleetForRebateClaim",tableRank: -1, detailsRank: -1, editable: false, type: "BOOLEAN"},
      fillingStationName: {label: "Filling station",tableRank: -1, detailsRank: 1, editable: true, type: "STRING"},
      totalPurchasePrice: {label: "Total price",tableRank: -1, detailsRank: 0, editable: true, type: "NUMBER"},
      tripSheetNumber: {label: "Trip sheet",tableRank: -1, detailsRank: 3, editable: true, type: "STRING"},
      // purchaseOrderNumber: {label: "Purchase order",tableRank: -1, detailsRank: 3, editable: true, type: "STRING"},
      transactionReviewed: { label: "Reviewed", tableRank: -1, detailsRank: -1, editable: false, type: "BOOLEAN"},
      isModified: {
        label: "Entry modified",
        tableRank: -1,
        detailsRank: 129,
        editable: false,
        type: "BOOLEAN",
      },
      documentNumber: {
        label: "Document #",
        tableRank: 3,
        detailsRank: 100,
        editable: false,
        type: "STRING",
      },
    }
}

