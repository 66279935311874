import React, { useEffect, useState } from "react";

import { Loader, Table, Timeline } from "@mantine/core";

import { Check, Checkbox, Eye, Fence, PlayerPause, PlayerPlay } from "tabler-icons-react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

async function fetchAndPushTemplateData(
  templateIdArray,
  templateName,
  templateArraySetter
) {
  const numberTemplates = templateIdArray.length || 0;
  for (let index = 0; index < numberTemplates; index++) {
    const id = templateIdArray[index];
    const templateData = await getDoc(doc(db, templateName, id));
    if (templateData.exists) {
      templateArraySetter((t) => [
        ...t,
        { template: templateName, data: { ...templateData.data() } },
      ]);
    }
  }
};

function toTimestampToDateString(value) {
  if (value?.seconds || value?._seconds) {
    return (
      new Date((value?.seconds || value?._seconds) * 1000).toLocaleDateString(
        "en-GB"
      ) +
        " " +
        new Date((value?.seconds || value?._seconds) * 1000).toLocaleTimeString(
          "en-GB"
        ) || "Unknown"
    );
  } else {
    return new Date(value).toLocaleDateString("en-GB") || "Unknown";
  }
};

function TripTimeline({ trip }) {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!trip.id) return;
    if (isLoading) return;
    const fetchData = async () => {
      setIsLoading(true);
      await fetchAndPushTemplateData(
        trip.capturedTripPreInspection,
        "capturedTripPreInspection",
        setTemplates
      );
      await fetchAndPushTemplateData(
        trip.capturedTripDepart,
        "capturedTripDepart",
        setTemplates
      );
      await fetchAndPushTemplateData(
        trip.capturedTripCheckPoint,
        "capturedTripCheckPoint",
        setTemplates
      );
      await fetchAndPushTemplateData(
        trip.capturedTripComplete,
        "capturedTripComplete",
        setTemplates
      );
      await fetchAndPushTemplateData(
        trip.capturedTripDelay,
        "capturedTripDelay",
        setTemplates
      );
      await fetchAndPushTemplateData(
        trip.capturedTripArrive,
        "capturedTripArrive",
        setTemplates
      );
      setIsLoading(false);
    };

    fetchData();

    return () => {
      setTemplates([]);
    };
  }, [trip]); // The empty dependency array ensures this effect runs only once on mount

  return (
    <div
      style={{
        padding: "1em",
        width: "100%",
        height: "100%",
        overflow: "scroll",
      }}
    >
      {isLoading ? (
        <Loader style={{ margin: "auto" }} />
      ) : (
        <>
          <h3>Details</h3>
          <Table style={{ border: "1px solid lightgray" }}>
            <tbody>
              <tr>
                <td>Description</td>
                <td>{trip.description}</td>
              </tr>
              <tr>
                <td>Pickup address</td>
                <td>{trip.pickupAddress}</td>
              </tr>
              <tr>
                <td>Planned pickup date</td>
                <td>{toTimestampToDateString(trip.pickupDate)}</td>
              </tr>
              <tr>
                <td>Delivery address</td>
                <td>{trip.deliveryAddress}</td>
              </tr>
              <tr>
                <td>Planned delivery date</td>
                <td>{toTimestampToDateString(trip.deliveryDate)}</td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>{trip.notes || "(empty)"}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{trip.status}</td>
              </tr>
              <tr>
                <td>Tracking active</td>
                <td>{trip.isTrackingActive ? "TRUE" : "FALSE"}</td>
              </tr>
              <tr>
                <td>External reference</td>
                <td>{trip.externalReference || "(empty)"}</td>
              </tr>
              <tr>
                <td>Vehicle</td>
                <td>{trip.fleetDisplayName || "(unassigned)"}</td>
              </tr>
              <tr>
                <td>Vehicle registration</td>
                <td>{trip.fleetRegistration || "(unassigned)"}</td>
              </tr>
              <tr>
                <td>Vehicle group</td>
                <td>{trip.fleetGroupName || "(unassigned)"}</td>
              </tr>
              <tr>
                <td>Driver</td>
                <td>{trip.fleetOperatorNameAndSurname || "(unassigned)"}</td>
              </tr>
              <tr>
                <td>Driver employee code</td>
                <td>{trip.fleetOperatorEmployeeCode || "(unassigned)"}</td>
              </tr>
              <tr>
                <td>Driver identification</td>
                <td>{trip.fleetOperatorIdentification || "(unassigned)"}</td>
              </tr>
              <tr>
                <td>Requires pre-inspection</td>
                <td>{trip.requiresPreInspection ? "TRUE" : "FALSE"}</td>
              </tr>
            </tbody>
          </Table>
          {templates.length > 0 && (<h3>Timeline</h3>)}
          <Timeline style={{ width: "100%" }}>
            {templates
              .sort((a, b) => a.data.deviceCaptured - b.data.deviceCaptured)
              .map((t, i) => {
                switch (t.template) {
                    case "capturedTripPreInspection":
                        return (
                          <Timeline.Item
                            key={i}
                            title={"Pre trip inspection"}
                            bullet={<Eye size={24} color="green" />}
                          >
                            <div>
                              Timestamp:{" "}
                              {toTimestampToDateString(t.data.deviceCaptured)}
                            </div>
                            <DocumentingPhotos t={t} />
                            <div>ODO: {t.data.odoOrHourReading}</div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>ODO photo:</div>
                                <div>
                                    {t.data?.odoOrHourReadingPhoto?.length > 0 &&
                                    t.data.odoOrHourReadingPhoto.map((photoSource, i) => (
                                        <img
                                            key={photoSource.path}
                                            src={photoSource.path}
                                            alt="Docs"
                                            style={{ maxWidth: "150px", margin: "5px" }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>Signature:</div>
                                <div>
                                    {t.data?.fleetOperatorSignedImage?.length > 0 &&
                                    t.data.fleetOperatorSignedImage.map((photoSource, i) => (
                                        <img
                                        key={photoSource.path}
                                        src={photoSource.path}
                                        alt="Docs"
                                        style={{ maxWidth: "150px", margin: '5px' }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div>Notes: {t.data.notes ?? "(empty)"}</div>
                          </Timeline.Item>
                        );
                  case "capturedTripArrive":
                    return (
                      <Timeline.Item
                        key={i}
                        title={"Arrive"}
                        bullet={<Fence size={24} color="green" />}
                      >
                        <div>
                          Timestamp:{" "}
                          {toTimestampToDateString(t.data.deviceCaptured)}
                        </div>
                        <div>Notes: {t.data.notes ?? "(empty)"}</div>
                      </Timeline.Item>
                    );
                  case "capturedTripDepart":
                    return (
                      <Timeline.Item
                        key={i}
                        title={"Depart"}
                        bullet={<PlayerPlay size={24} color="green" />}
                      >
                        <div>
                          Timestamp:{" "}
                          {toTimestampToDateString(t.data.deviceCaptured)}
                        </div>
                        <div>Notes: {t.data.notes ?? "(empty)"}</div>
                      </Timeline.Item>
                    );
                  case "capturedTripCheckPoint":
                    return (
                      <Timeline.Item
                        key={i}
                        title={"Checkpoint"}
                        bullet={<Check size={24} color="green" />}
                      >
                        <div>
                          Timestamp:{" "}
                          {toTimestampToDateString(t.data.deviceCaptured)}
                        </div>
                        <div>Notes: {t.data.notes ?? "(empty)"}</div>
                        <DocumentingPhotos t={t} />
                      </Timeline.Item>
                    );
                  case "capturedTripDelay":
                    return (
                      <Timeline.Item
                        key={i}
                        title={"Delay"}
                        bullet={<PlayerPause size={24} color="green" />}
                      >
                        <div>
                          Timestamp:{" "}
                          {toTimestampToDateString(t.data.deviceCaptured)}
                        </div>
                        <div>Notes: {t.data.notes ?? "(empty)"}</div>
                        <DocumentingPhotos t={t} />
                      </Timeline.Item>
                    );
                  case "capturedTripComplete":
                    return (
                      <Timeline.Item
                        key={i}
                        title={"Complete"}
                        bullet={<Checkbox size={24} color="green" />}
                      >
                        <div>
                          Timestamp:{" "}
                          {toTimestampToDateString(t.data.deviceCaptured)}
                        </div>
                        <div>Notes: {t.data.notes ?? "(empty)"}</div>
                        <DocumentingPhotos t={t} />
                      </Timeline.Item>
                    );
                  default:
                    return <div key={i}>Unknown template type</div>;
                }
              })}
          </Timeline>
        </>
      )}
    </div>
  );
}

function DocumentingPhotos({ t }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>Documenting photos:</div>
      <div>
        {t.data?.documentingPhotos?.length > 0 &&
          t.data.documentingPhotos.map((photoSource, i) => (
            <img
              key={photoSource.path}
              src={photoSource.path}
              alt="Docs"
              style={{ maxWidth: "150px", margin: "5px" }}
            />
          ))}
      </div>
    </div>
  );
};


export default TripTimeline;