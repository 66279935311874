import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Grid,
  InputWrapper,
  NumberInput,
  Text,
  TextInput,
  Select,
  Input,
  Button,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { Clock, Plus, Trash } from "tabler-icons-react";

export function DataEntry({
  label,
  value,
  edit,
  inputProps,
  dataType,
  form,
  fieldKey,
  selectOptions = [],
  selectLabelKey,
  shouldIncludeTimeForDate = true,
}) {
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
    if (dataType === "DATE" && edit) {
      setDate(new Date(value));
      setTime(new Date(value));
    }
  }, [edit, dataType]);

  const handleDateChange = (d, t) => {
    if (dataType === "DATE") {
      if (d) {
        setDate(d);
      }
      if (t) {
        setTime(t);
      }
    }
  };

  const handleArrayChange = (event, index) => {
    const existingValues = form.values[fieldKey];
    existingValues[index] = event.target.value;
    form.setFieldValue(fieldKey, existingValues);
  }

  const handleArrayAdd = () => {
    form.setFieldValue(fieldKey, [...form.values[fieldKey], '']);
  }

  const handleArrayRemove = (index) => {
    const existingValues = form.values[fieldKey];
    existingValues.splice(index, 1);
    
    form.setFieldValue(fieldKey, [...existingValues]);
  }

  useEffect(() => {
    if (date && time) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = new Date(
        date?.getFullYear(),
        date?.getMonth(),
        date?.getDate(),
        time?.getHours(),
        time?.getMinutes()
      );
      form.setFieldValue(
        fieldKey,
        formattedDate.toLocaleDateString("en-GB", options) +
          " " +
          formattedDate.toLocaleTimeString(options)
      );
    }
  }, [date, time]);
  return (
    <>
      <Grid columns={16} gutter={0}>
        <Grid.Col
          span={8}
          style={{ textAlign: "start", alignContent: "center" }}
        >
          <Text weight={500}>{label}</Text>
        </Grid.Col>
        <Grid.Col span={8} style={{ textAlign: "end", alignContent: "center" }}>
          {!!edit
            ? (() => {
                switch (dataType) {
                  case "NUMBER":
                    return (
                      <NumberInput
                        size="md"
                        {...inputProps}
                        value={value}
                        hideControls={true}
                        precision={2}
                        step={0.01}
                      />
                    );
                  case "STRING":
                    return (
                      <InputWrapper>
                        <TextInput
                          size="md"
                          type="text"
                          {...inputProps}
                        ></TextInput>
                      </InputWrapper>
                    );
                  case "STRING_ARRAY":
                    return (
                      <>
                        {(value || []).map((item, i) => (
                          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1em'}} key={i}>
                            <TextInput 
                                key={i}
                                value={item}
                                size="md"
                                onChange={e => handleArrayChange(e,i)}/>
                            <Button color="red" variant="outline" styles={{height: "100%"}}onClick={() => handleArrayRemove(i)} size="sm">
                              <Trash />
                            </Button>
                          </div>
                          ))
                        }
                        <Button color="cap-navy" onClick={handleArrayAdd} size="md" style={{maxWidth: '50%', minWidth: '25%'}} leftIcon={<Plus />}>Add</Button>
                      </>
                    );
                  case "DATE":
                    return (
                      <>
                        <DatePicker
                          value={date}
                          onChange={(d) => handleDateChange(d, null)}
                        />
                        {shouldIncludeTimeForDate && (
                          <>
                            {" "}
                            <TimeInput
                              icon={<Clock size={18} />}
                              value={time}
                              onChange={(t) => handleDateChange(null, t)}
                            />
                          </>
                        )}
                      </>
                    );
                  case "BOOLEAN":
                    return (
                      <Checkbox
                        checked={form.values[fieldKey]}
                        onChange={(v) => 
                          form.setFieldValue(fieldKey, v.currentTarget.checked)
                        }
                      />
                    );
                  case "SELECT":
                    return (
                      <Select
                        allowDeselect
                        data={selectOptions.map((option) => {
                          return {
                            value: option.id,
                            label: option[selectLabelKey],
                          };
                        })}
                        {...inputProps}
                      />
                    );
                  default:
                    return (
                      <Text style={{ wordWrap: "break-word" }}>{value}</Text>
                    );
                }
              })()
            : (() => {
                switch (dataType) {
                  case "COORDINATE":
                    return (
                      <a
                        href={`https://www.google.com/maps?q=${
                          value.split(", ")[1]
                        },${[value.split(", ")[0]]}&ll=${
                          value.split(", ")[1]
                        },${[value.split(", ")[0]]}&z=14`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Text style={{ wordWrap: "break-word" }}>{value}</Text>
                      </a>
                    );
                  case "BOOLEAN":
                    return (
                        <Text style={{ wordWrap: "break-word" }}>{value? "TRUE": "FALSE"}</Text>
                      );
                  default:
                    return (
                      <Text style={{ wordWrap: "break-word" }}>{value}</Text>
                    );
                }
              })()}
        </Grid.Col>
      </Grid>
    </>
  );
}
