import React, { useState, useEffect } from "react";

import {
  Divider,
  Notification,
  Skeleton,
  Space,
  Tabs,
  Text,
  useMantineTheme,
} from "@mantine/core";

import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";

import { InfoCircle } from "tabler-icons-react";
import { TankTable } from "./tables/tank-table";
import { VehicleTable } from "./tables/vehicle-table";
import { SupplierTable } from "./tables/supplier-table";
import { VehicleOperatorsTable } from "./tables/vehicle-operator-table";
import { ActivitiesTable } from "./tables/activities-table";
import { ProductsTable } from "./tables/products-table";
import { ZonesTable } from "./tables/zones-table";
import { VehicleGroupsTable } from "./tables/vehicle-group-table";

export function Assets({ companyId, auth }) {
  const theme = useMantineTheme();
  const [message, setMessage] = useState("");
  const [tanks, setTanks] = useState([]);
  const [fetchedTanks, setFetchedTanks] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [fetchedSuppliers, setFetchedSuppliers] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [fetchedVehicles, setFetchedVehicles] = useState(true);
  const [vehicleGroups, setVehicleGroups] = useState([]);
  const [fetchedVehicleGroups, setFetchedVehicleGroups] = useState(true);
  const [operators, setOperators] = useState([]);
  const [fetchedOperators, setFetchedOperators] = useState(true);
  const [activities, setActivities] = useState([]);
  const [fetchedActivities, setFetchedActivities] = useState(true);
  const [products, setProducts] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState(true);
  const [zones, setZones] = useState([]);
  const [fetchedZones, setFetchedZones] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const onChange = (active, tabKey) => {
    setActiveTab(active);
    if (tabKey === "tanks" && !tanks?.length) {
      setFetchedTanks(false);
    } else if (tabKey === "suppliers" && !suppliers?.length) {
      setFetchedSuppliers(false);
    } else if (tabKey === "vehicles" && !vehicles?.length) {
      setFetchedVehicleGroups(false);
      setFetchedVehicles(false);
    } else if (tabKey === "vehicleGroups" && !vehicleGroups?.length) {
      setFetchedVehicleGroups(false);
    } else if (tabKey === "vehicleOperators" && !operators?.length) {
      setFetchedOperators(false);
    } else if (tabKey === "activities" && !activities?.length) {
      setFetchedActivities(false);
    } else if (tabKey === "products" && !products?.length) {
      setFetchedProducts(false);
    } else if (tabKey === "zones" && !zones?.length) {
      setFetchedZones(false);
    }
  };

  const handleResults = (documentSnapshots, setter) => {
    let entries = [];
    documentSnapshots.forEach((doc) =>
      entries.push({ id: doc.id, ...doc.data() })
    );
    setter(entries);
  };

  const buildQuery = (templateName) => {
    return query(
      collection(db, templateName),
      where("companyId", "==", companyId)
    );
  };

  useEffect(() => {
    if (!fetchedTanks) {
      async function fetchTanks() {
        setFetchedTanks(true);
        const documentSnapshots = await getDocs(buildQuery("tanks"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("tanks");
        }
        handleResults(documentSnapshots, setTanks);
      }
      fetchTanks();
    }
    if (!fetchedSuppliers) {
      async function fetchSuppliers() {
        setFetchedSuppliers(true);
        const documentSnapshots = await getDocs(buildQuery("suppliers"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("suppliers");
        }
        handleResults(documentSnapshots, setSuppliers);
      }
      fetchSuppliers();
    }
    if (!fetchedVehicles) {
      async function fetchVehicles() {
        setFetchedVehicles(true);
        const documentSnapshots = await getDocs(buildQuery("fleet"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("vehicles");
        }
        handleResults(documentSnapshots, setVehicles);
      }
      fetchVehicles();
    }
    if (!fetchedVehicleGroups) {
      async function fetchVehicleGroups() {
        setFetchedVehicleGroups(true);
        const documentSnapshots = await getDocs(buildQuery("fleetGroups"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("vehicle groups");
        }
        handleResults(documentSnapshots, setVehicleGroups);
      }
      fetchVehicleGroups();
    }
    if (!fetchedOperators) {
      async function fetchOperators() {
        setFetchedOperators(true);
        const documentSnapshots = await getDocs(buildQuery("fleetOperators"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("vehicle operators");
        }
        handleResults(documentSnapshots, setOperators);
      }
      fetchOperators();
    }
    if (!fetchedActivities) {
      async function fetchActivities() {
        setFetchedActivities(true);
        const documentSnapshots = await getDocs(buildQuery("activities"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("activities");
        }
        handleResults(documentSnapshots, setActivities);
      }
      fetchActivities();
    }
    if (!fetchedProducts) {
      async function fetchProducts() {
        setFetchedProducts(true);
        const documentSnapshots = await getDocs(buildQuery("products"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("products");
        }
        handleResults(documentSnapshots, setProducts);
      }
      fetchProducts();
    }
    if (!fetchedZones) {
      async function fetchZones() {
        setFetchedZones(true);
        const documentSnapshots = await getDocs(buildQuery("areaForFleetUse"));
        if (documentSnapshots.size === 0) {
          setAssetMessage("zones");
        }
        handleResults(documentSnapshots, setZones);
      }
      fetchZones();
    }
  }, [
    fetchedTanks,
    fetchedSuppliers,
    fetchedVehicles,
    fetchedVehicleGroups,
    fetchedOperators,
    fetchedActivities,
    fetchedProducts,
    fetchedZones,
  ]);

  const setAssetMessage = (asset) => {
    setMessage(
      `No ${asset} configured. Open the mobile application and go to 'Settings' to configure Company assets.`
    );
  };

  useEffect(() => {
    if (companyId) setFetchedVehicles(false);
  }, [companyId]);

  return (
    <>
      {companyId && (
        <>
          <Text size="xl" weight="semibold">
            Assets
          </Text>
          <Space h="sm" />
          <Divider />
          <Space h="sm" />
          <Tabs active={activeTab} onTabChange={onChange} styles={{width:"100%", color: theme.colors['cap-navy']}}>
            <Tabs.Tab color="cap-navy" label="Vehicles" tabKey="vehicles">
              {vehicles && vehicles?.length ? (
                <VehicleTable elements={vehicles} auth={auth} reloadCallback={() => setFetchedVehicles(false)} vehicleGroups={vehicleGroups}/>
              ) : <Skeleton />}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Fleet operators" tabKey="vehicleOperators">
              {operators && operators?.length ? (
                <VehicleOperatorsTable elements={operators} auth={auth} reloadCallback={() => setFetchedOperators(false)} />
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Vehicle groups" tabKey="vehicleGroups">
              {vehicleGroups && vehicleGroups?.length ? (
                <VehicleGroupsTable elements={vehicleGroups} auth={auth} reloadCallback={() => setFetchedVehicleGroups(false)} />
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Tanks" tabKey="tanks" >
              {tanks && tanks?.length ? (
                <TankTable elements={tanks} auth={auth} reloadCallback={() => setFetchedTanks(false)} vehicleGroups={vehicleGroups}/>
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Suppliers" tabKey="suppliers">
              {suppliers && suppliers?.length ? (
                <SupplierTable elements={suppliers} auth={auth} reloadCallback={() => setFetchedSuppliers(false)}  />
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Activities" tabKey="activities">
              {activities && activities?.length ? (
                <ActivitiesTable elements={activities} auth={auth} reloadCallback={() => setFetchedActivities(false)} />
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Products" tabKey="products">
              {products && products?.length ? (
                <ProductsTable elements={products} auth={auth} reloadCallback={() => setFetchedProducts(false)} />
              ) : null}
            </Tabs.Tab>
            <Tabs.Tab color="cap-navy" label="Zones" tabKey="zones">
              {zones && zones?.length ? (
                <ZonesTable elements={zones}auth={auth} reloadCallback={() => setFetchedZones(false)}  />
              ) : null}
            </Tabs.Tab>
          </Tabs>
          {message && (
            <Notification
              icon={<InfoCircle size={18} />}
              color="cap-blue"
              title="Info"
              style={{
                position: "absolute",
                bottom: "20px",
                right: "20px",
                zIndex: 1000,
              }}
              onClose={() => setMessage("")}
            >
              {message}
            </Notification>
          )}
        </>
      )}
    </>
  );
}
