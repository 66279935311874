import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { doc, getDoc } from "firebase/firestore";

import {
  Alert,
  Button,
  Divider,
  Drawer,
  Image,
  Loader,
  MediaQuery,
  Popover,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import { db } from "../../firebase";
import { DataEntry } from "../shared";
import { fetchAndDownloadReport } from "../shared/hooks/generate-report";

import { isNumber, formatValue } from "./hooks";
import { deleteTemplate, updateTemplate } from "../shared/hooks/data-functions";
import {
  Check,
  Cross,
} from "tabler-icons-react";
import DownloadAlert from "../shared/downloadAlert";
import VerifiedIcon from "./verified-icon"

export function DataEntryTable({
  elements,
  config,
  setDocuments,
  templateName,
  companyId,
}) {
  const [opened, setOpened] = useState(false);
  const [focus, setFocused] = useState();
  const [doEdit, setDoEdits] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [renderType, setRendertType] = useState();
  const [reportName, setReportName] = useState();
  const [Base64File, setBase64File] = useState();
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  let tableSortedKeys;
  let detailsSortedKeys;
  let initialValues = {};
  let validate = {};

  const fieldConfig = config.fieldConfig;
  const reportConfig = config.reportConfig;

  let editableKeys = useMemo(
    () => Object.keys(fieldConfig).filter((c) => fieldConfig[c].editable),
    [fieldConfig]
  );

  tableSortedKeys = Object.keys(fieldConfig)
    .filter((c) => fieldConfig[c].tableRank >= 0)
    .sort((a, b) => fieldConfig[a].tableRank - fieldConfig[b].tableRank);

  detailsSortedKeys = Object.keys(fieldConfig)
    .filter((c) => fieldConfig[c].detailsRank >= 0)
    .sort((a, b) => fieldConfig[a].detailsRank - fieldConfig[b].detailsRank);

  editableKeys.forEach((v) => (initialValues[v] = undefined));

  editableKeys
    .filter((c) => fieldConfig[c].type === "NUMBER")
    .forEach((v) => (validate[v] = (value) => isNumber(value)));

  const form = useForm({
    initialValues,
    validate,
  });

  useEffect(() => {
    if (focus) {
      editableKeys.forEach((k) => {
        form.setFieldValue(k, formatValue(fieldConfig[k].type, focus[k]));
      });
    }
  }, [focus]);

  const handleTransactionReviewed = async () => {
    setLoading(true);
    const updateResp = await updateTemplate(templateName, focus.id, companyId, {
      transactionReviewed: true,
    });
    if (updateResp.code === 200) {
      setNotification({
        success: true,
        message: "Entry marked as reviewed",
      });
      const updatedValue = { ...focus, transactionReviewed: true };

      setDocuments((docs) => {
        const index = docs.findIndex((d) => d.id === focus.id);
        docs[index] = updatedValue;
        return docs;
      });
    } else {
      setNotification({
        success: false,
        message: "Entry review failed",
      });
    }
    setTimeout(() => {
      setNotification(undefined);
      setLoading(false);
      setOpened(false);
    }, 2000);
  };

  const handleTransactionDeleted = async () => {
    setLoading(true);
    const updateResp = await deleteTemplate(templateName, focus.id, companyId);
    setLoading(false);
    if (updateResp.code === 200) {
      setNotification({
        success: true,
        message: "Entry deleted",
      });
      setDocuments((docs) => {
        const index = docs.findIndex((d) => d.id === focus.id);
        docs.splice(index, 1);
        return docs;
      });
      setOpened(false);
    } else {
      setNotification({
        success: false,
        message: "Entry deletion failed",
      });
    }
    setTimeout(() => {
      setNotification(undefined);
    }, 2000);
  };

  const updateEntry = useCallback(
    async (values) => {
      const handleError = (error) => {
        setNotification({
          success: false,
          message: "Entry could not be updated.",
        });
        console.error(error);
      };
      try {
        setLoading(true);
        const updateResp = await updateTemplate(
          templateName,
          focus.id,
          companyId,
          values
        );
        setLoading(false);

        if (updateResp.code === 200) {
          const formattedResponse = {};
          Object.keys(updateResp.data)
            .filter((k) => editableKeys.includes(k))
            .forEach(
              (k) =>
                (formattedResponse[k] = formatValue(
                  fieldConfig[k].type,
                  updateResp.data[k]
                ))
            );
          const updatedValue = { ...focus, ...formattedResponse };

          setDocuments((docs) => {
            const index = docs.findIndex((d) => d.id === focus.id);
            docs[index] = updatedValue;
            return docs;
          });
          setFocused(updatedValue);
          setDoEdits(false);
          setNotification({
            success: true,
            message: "Entry successfully updated.",
          });
        } else {
          handleError("Unknown update failure");
        }
      } catch (error) {
        handleError(error);
      }
      setTimeout(() => {
        setNotification(undefined);
      }, 2000);
    },
    [focus, setDocuments, templateName]
  );

  const fetchReportConfig = async () => {
    const documentSnapshot = await getDoc(
      doc(db, "reportSetUp", reportConfig.reportId)
    );

    if (!documentSnapshot.exists) {
      throw new Error("report config doesn't exist or is inactive.");
    }

    return documentSnapshot.data();
  };

  const handleGenerateReport = async (reportId, documentId) => {
    const reportToGenerate = await fetchReportConfig();

    const reportRenderType = reportToGenerate.renderingType;
    const reportName =
      reportToGenerate.reportShortName +
      `-${new Date().toISOString().split("T")[0]}`;

    try {
      const result = await fetchAndDownloadReport(
        reportId,
        null,
        null,
        reportRenderType,
        reportName,
        documentId,
        companyId
      );
  
      setRendertType(reportRenderType);
      setReportName(reportName);
      setBase64File(result);
      setDownloadModalOpen(true);
    } catch (error) {
      alert("Your report could not be generated. An error notification has been sent to the CaptivaData team for further investigation.");      
    }

    setLoading(false);
  };

  const rows = elements.map((element, i) => (
    <Fragment key={element.id + i}>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <tr key={element.id}>
          <td>
            <VerifiedIcon element={element} />
            {/* {element["transactionReviewed"] ? (
              <CircleCheck color="green" />
            ) : (
              <CircleDotted color="gray" />
            )} */}
          </td>
          {tableSortedKeys.map((key) => (
            <td key={key}>
              {formatValue(fieldConfig[key]?.type, element[key])}
            </td>
          ))}
          <td>
            <Button
              size="md"
              color="cap-navy"
              variant="outline"
              onClick={() => {
                setOpened(true);
                setFocused(element);
              }}
            >
              View
            </Button>
          </td>
        </tr>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <tr key={element.id}>
          <td>
            <VerifiedIcon element={element} />
          </td>
          {tableSortedKeys.slice(0, 3).map((key) => (
            <td key={key}>
              {formatValue(fieldConfig[key]?.type, element[key])}
            </td>
          ))}
          <td>
            <Button
              size="md"
              color="cap-navy"
              onClick={() => {
                setOpened(true);
                setFocused(element);
              }}
            >
              View
            </Button>
          </td>
        </tr>
      </MediaQuery>
    </Fragment>
  ));

  const Buttons = () => {
    const [moreOpened, setMoreOpened] = useState(false);
    return (
      <Fragment>
        {doEdit && (
          <Button size="md" color="cap-purple" fullWidth type="submit">
            Save
          </Button>
        )}
        {!doEdit && (
          <>
            <Button
              color="cap-purple"
              fullWidth
              variant="outline"
              size="md"
              type="button"
              onClick={() => setDoEdits(true)}
            >
              Edit
            </Button>
            <Popover
              opened={moreOpened}
              onClose={() => setMoreOpened(false)}
              target={
                <Button
                  color="cap-navy"
                  fullWidth
                  size="md"
                  type="button"
                  onClick={() => setMoreOpened((o) => !o)}
                >
                  More actions
                </Button>
              }
              width={260}
              position="bottom"
              withArrow
            >
              <div style={{ display: "grid", gap: "12px" }}>
                {!/iPhone|iPod/.test(navigator.userAgent) && (
                  <>
                    <Button
                      color="cap-blue"
                      size="md"
                      fullWidth
                      type="button"
                      disabled={!config?.reportConfig?.reportId}
                      onClick={() => {
                        handleGenerateReport(
                          config.reportConfig.reportId,
                          focus.id
                        );
                        setLoading(true);
                      }}
                    >
                      Generate report
                    </Button>
                  </>
                )}
                {focus["transactionReviewed"] !== undefined && (
                  <Button
                    color="cap-green"
                    size="md"
                    fullWidth
                    type="button"
                    disabled={focus["transactionReviewed"]}
                    onClick={handleTransactionReviewed}
                  >
                    Mark as reviewed
                  </Button>
                )}
                <Button
                  color="red"
                  fullWidth
                  size="md"
                  type="button"
                  onClick={handleTransactionDeleted}
                >
                  Delete
                </Button>
              </div>
            </Popover>
          </>
        )}
      </Fragment>
    );
  };

  return (
    <Table width={"100%"}>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <thead>
          <tr>
            <th> </th>
            {tableSortedKeys.map((key) => (
              <th key={key}>{fieldConfig[key]?.label}</th>
            ))}
            <th>Details</th>
          </tr>
        </thead>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <thead>
          <tr>
            <th> </th>
            {tableSortedKeys.slice(0, 3).map((key) => (
              <th key={key}>{fieldConfig[key]?.label}</th>
            ))}
            <th>Details</th>
          </tr>
        </thead>
      </MediaQuery>

      <tbody>{rows}</tbody>
      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
          setDoEdits(false);
        }}
        title="Data Entry"
        padding="lg"
        size="800px"
        position="right"
      >
        {!!notification && (
          <Alert
            icon={
              notification?.success ? <Check size={18} /> : <Cross size={18} />
            }
            color={notification?.success ? "green" : "red"}
            title="Data update"
          >
            {notification.message}
          </Alert>
        )}
        <Divider />
        <div style={{ overflow: "scroll", height: "100%" }}>
          {focus && (
            <div style={{ marginBottom: "8em" }}>
              <form onSubmit={form.onSubmit((values) => updateEntry(values))}>
                <Stack
                  style={{
                    marginTop: "1em",
                    marginBottom: "10em",
                    overflow: "scroll",
                  }}
                >
                  {detailsSortedKeys.map((key) => (
                    <div key={key}>
                      {fieldConfig[key]?.type !== "IMAGE" ? (
                        // display non-image type
                        <DataEntry
                          label={fieldConfig[key]?.label}
                          edit={doEdit && fieldConfig[key]?.editable}
                          inputProps={form.getInputProps(key)}
                          dataType={fieldConfig[key]?.type}
                          form={form}
                          fieldKey={key}
                          value={formatValue(
                            fieldConfig[key]?.type,
                            focus[key]
                          )}
                        />
                      ) : (
                        // display image type
                        <>
                          <Text weight={500}>{fieldConfig[key]?.label}</Text>
                          <div
                            style={{
                              width: 250,
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "1em",
                            }}
                          >
                            <div style={{ display: "grid", rowGap: "1em" }}>
                              {!!focus[key].length
                                ? focus[key]?.map((imageMap, i) => (
                                    <Image key={i} src={imageMap?.path} />
                                  ))
                                : "No image"}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </Stack>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    // negative margin to counteract mantine padding
                    margin: "0 -20px",
                    backgroundColor: "white",
                  }}
                >
                  {/* Larger screen size */}
                  <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                    <div>
                      {loading ? (
                        <Loader
                          style={{ margin: "1em auto", width: "100%" }}
                          color="blue"
                        ></Loader>
                      ) : (
                        <div
                          style={{
                            display: "grid",
                            columnGap: "1em",
                            gridAutoColumns: "1fr",
                            gridAutoFlow: "column",
                            margin: "20px",
                          }}
                        >
                          <Buttons />
                        </div>
                      )}
                    </div>
                  </MediaQuery>
                  {/* Smaller screen size */}
                  <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                    <div>
                      {loading ? (
                        <Loader
                          style={{ margin: "1em auto", width: "100%" }}
                          color="blue"
                        ></Loader>
                      ) : (
                        <div
                          style={{
                            display: "grid",
                            rowGap: "1em",
                            gridAutoRows: "1fr",
                            gridAutoFlow: "row",
                            margin: "20px",
                          }}
                        >
                          <Buttons />
                        </div>
                      )}
                    </div>
                  </MediaQuery>
                </div>
              </form>
            </div>
          )}
        </div>
      </Drawer>
      <DownloadAlert
        renderType={renderType}
        reportName={reportName}
        Base64File={Base64File}
        modalOpen={downloadModalOpen}
        onModalClose={() => setDownloadModalOpen(false)}
      ></DownloadAlert>
    </Table>
  );
}
