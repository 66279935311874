import React, { Fragment, useState } from "react";

import { Button, MediaQuery, Modal, Table } from "@mantine/core";

import { QrDisplay } from "../../components/shared/qr-display";
import { TanksAssetConfig } from "../../components/listings/configurations/assets/tank-asset-config";
import { AssetEditor } from "../../components/shared/asset-editor";
import { Plus, Qrcode } from "tabler-icons-react";

export function TankTable({ elements, auth, reloadCallback }) {
  const newTank = {
    name: "",
    size: "",
    mobileTank: false,
    isActive: false,
    fuelReceiveBulkDelivery: false,
    fuelType: "",
  };
  const [qrValue, setQrValue] = useState("");
  const [qrLabel, setQrLabel] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [editedElement, setEditedElement] = useState(undefined);
  const closeEdit = () => setEditedElement(undefined);

  const EditButton = ({element}) => (
    <Button
      color={"cap-navy"}
      variant="outline"
      onClick={() => {
        setEditedElement(element);
      }}
    >
      Edit
    </Button>
  );
  const rows = elements.map((element) => (
    <Fragment key={element.id}>
      {/* // desktop sizes */}
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element.name}</td>
          <td>{element.size}</td>
          <td>{element.isActive.toString()}</td>
          <td>{element.fuelType}</td>
          <td>
            <Button
              color={"cap-navy"}
              variant="outline"
              onClick={() => {
                setQrLabel(element.name);
                setQrValue(element.id);
                setIsExpanded(true);
              }}
              leftIcon={<Qrcode />}
            >
              Print QR
            </Button>
          </td>
          <td>
              <EditButton element={element} />
          </td>
        </tr>
      </MediaQuery>
      {/* // phone sizes */}
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element.name}</td>
          <td>{element.size}</td>
          <td>
              <EditButton element={element} />
          </td>
        </tr>
      </MediaQuery>
    </Fragment>
  ));

  return (
    <>
      <Table width={"100%"}>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Tank name</th>
              <th>Size (litres)</th>
              <th>Active</th>
              <th>Fuel type</th>
              <th>Asset QR</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Tank name</th>
              <th>Size (litres)</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <tbody>{rows}</tbody>
        <Modal
          opened={isExpanded}
          onClose={() => setIsExpanded(false)}
          closeOnClickOutside
        >
          <QrDisplay qrData={qrValue} qrLabel={qrLabel} assetName={"Tank"} />
        </Modal>
      </Table>
      {editedElement && (
        <Modal opened onClose={closeEdit} title="Tank">
          <AssetEditor
            asset={editedElement}
            assetConfig={TanksAssetConfig}
            collection="tanks"
            auth={auth}
            reloadCallback={() => {
              reloadCallback();
              closeEdit();
            }}
          ></AssetEditor>
        </Modal>
      )}
      <Button
        onClick={() => setEditedElement(newTank)}
        fullWidth
        my="xl"
        color="cap-navy"
        variant="outline"
        leftIcon={<Plus />}
      >
        Create new
      </Button>
    </>
  );
}
