import React, { useState } from "react";

import { Alert, Button, Grid, Text } from "@mantine/core";

import { useForm } from "@mantine/form";
import { formatValue } from "../listings/hooks";
import { DataEntry } from "./data-entry";
import { updateDoc, doc, deleteDoc, addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

export function AssetEditor({
  asset,
  assetConfig,
  collection: targetCollection,
  auth,
  reloadCallback,
  dependencyList,
  dependencyLabel,
  useCallbackOnSave
}) {
  const [initialValues] = useState({ ...asset });
  const [deleteCandidate, setDeleteCandidate] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [messageColor, setMessageColor] = useState('green');
  function buildValidationRules(assetConfig) {
    let validationRulesConfig = {};
    Object.keys(assetConfig).forEach((key) => {
      // check if required (ignore booleans)
      if (assetConfig[key].required && assetConfig[key].type !== "BOOLEAN") {
        validationRulesConfig[key] = (value) => (!value ? "Required" : null);
      }
      // right now all numbers are required check if positive number
      // todo future: handle non-required number case
      if (assetConfig[key].type === "NUMBER") {
        validationRulesConfig[key] = (value) =>
          isNaN(Number(value)) || Number(value) < 0
            ? "Must be a positive number"
            : null;
      }
    });
    return validationRulesConfig;
  }

  const form = useForm({
    initialValues,
    validate: buildValidationRules(assetConfig),
  });

  async function saveAsset(updatedAsset) {
    if (!targetCollection || !auth?.claims?.companyId || !updatedAsset) {
      setMessageColor("red")
      setMessage("Save unsuccessful.");
    }
    Object.keys(assetConfig).forEach((key) => {
      if(assetConfig[key].type === "DATE") {
        updatedAsset[key] = new Date(updatedAsset[key]).toISOString();
      }
    });

    try {
      // parent will handle save
      if(useCallbackOnSave) {
        reloadCallback(updatedAsset);
      } else {
        // handle save here
        if (updatedAsset.id) {
          // save
          const docRef = doc(db, targetCollection, updatedAsset.id);
          await updateDoc(docRef, updatedAsset);
          setMessageColor("green")
          setMessage("Successfully updated!");
          reloadCallback(updatedAsset);
        } else {
          // create
          const createdAsset = {
            ...updatedAsset,
            companyId: auth.claims.companyId,
          }
          const docRef = await addDoc(collection(db, targetCollection), createdAsset);
          await updateDoc(docRef, {id: docRef.id});
          setMessageColor("green")
          setMessage("Successfully created!");
          reloadCallback(createdAsset);
        }
      }
    } catch (error) {
      setMessageColor("red");
      setMessage("Save unsuccessful.", error.toString());
    }
  }

  async function deleteAsset(deleteCandidate) {
    try {
      const docRef = doc(db, targetCollection, deleteCandidate.id);
      await deleteDoc(docRef);
      setMessageColor("green")
      setMessage("Delete successful.");
      reloadCallback();
    } catch (error) {
      setMessageColor("red")
      setMessage("Delete unsuccessful.", error.toString());
    }
    setDeleteCandidate(undefined);
  }

  return (
    <>
      <form onSubmit={form.onSubmit((values) => saveAsset(values))}>
        {Object.keys(initialValues)
          .filter((k) => assetConfig[k])
          .sort((a, b) => assetConfig[a].rank - assetConfig[b].rank)
          .map((key) => (
            <div
              key={key}
              style={{
                marginTop: "1em",
              }}
            >
              <DataEntry
                label={assetConfig[key]?.label}
                edit={assetConfig[key]?.editable}
                inputProps={form.getInputProps(key)}
                dataType={assetConfig[key]?.type}
                form={form}
                fieldKey={key}
                selectOptions={dependencyList && dependencyList[key]}
                selectLabelKey={dependencyList && dependencyLabel[key]}
                value={formatValue(assetConfig[key]?.type, form.values[key])}
                shouldIncludeTimeForDate={false}
              />
            </div>
          ))}
        <Button size="md" color="cap-navy" fullWidth type="submit" mt="md">
          Save
        </Button>
        {message && <Alert color={messageColor}>{message}</Alert>}
      </form>
      {asset.id && (
        <>
          {!!deleteCandidate ? (
              <Grid my="md">
                <Grid.Col span={4} styles={{display: "flex", alignItems:"center"}}>
                  <Text size="lg">Are you sure?</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button
                    color={"cap-navy"}
                    fullWidth
                    onClick={() => setDeleteCandidate(undefined)}
                  >
                    Cancel
                  </Button>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button color="red" onClick={() => deleteAsset(asset)} fullWidth>
                    Yes - Delete
                  </Button>
                </Grid.Col>
              </Grid>
          ) : (
            <Button
              size="md"
              color="red"
              fullWidth
              mt="md"
              onClick={() => setDeleteCandidate(asset)}
            >
              Delete
            </Button>
          )}
        </>
      )}
    </>
  );
}