import { httpsCallable } from "firebase/functions";

import { functions } from "../../../firebase";

const getReport = httpsCallable(functions, "fetchRawReport");

const getReportDownloadLink = httpsCallable(functions, "fetchAndStoreReport");

// This works for excel files, and on mobile
export const fetchReportDownloadLink = async (
  reportId,
  startDate,
  endDate,
  renderType,
  reportName,
  documentId,
  companyId,
  uid,
  addlFieldMap = null
) => {
  const result = await getReportDownloadLink({
    reportId: reportId,
    reportName: reportName,
    startDate: startDate,
    endDate: endDate,
    renderType,
    documentId,
    companyId,
    uid,
    addlFieldMap,
  });

  return result.data;
};


// This works for PDFs, but only on desktop (ugh)
export const fetchAndDownloadReport = async (
  reportId,
  startDate,
  endDate,
  renderType,
  reportName,
  documentId
) => {
  const result = await getReport({
    reportId: reportId,
    reportName: reportName,
    startDate: startDate,
    endDate: endDate,
    renderType,
    documentId,
  });

  return result.data;
};
