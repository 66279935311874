import {
  Button,
  Divider,
  Grid,
  Loader,
  Notification,
  Select,
  Table,
  MediaQuery,
} from "@mantine/core";
import { Check, Flag, InfoCircle } from "tabler-icons-react";
import {
  associateUserWithFleetOperator,
  downgradeToUser,
  upgradeToAdmin,
} from "../components/shared/hooks/user-management";
import { useEffect, useState, Fragment } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

function UserTable({ elements, updateCallback, userId, companyId }) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTripsEnabled, setIsTripsEnabled] = useState(false);
  const [isOperatorsFetched, setIsOperatorsFetched] = useState(true);
  const [fleetOperators, setFleetOperators] = useState([]);
  useEffect(() => {
    async function fetchFlags() {
      const documentSnapshot = await getDoc(
        doc(db, "featureFlags", "hA0T8o3RipWMnJQDnw4p")
      );

      if (documentSnapshot.data().trips.includes(companyId)) {
        setIsTripsEnabled(true);
        setIsOperatorsFetched(false);
      }
    }
    fetchFlags();
  }, []);

  useEffect(() => {
    async function fetchTrips() {
      setIsOperatorsFetched(true);
      const tripsSnapshots = await getDocs(
        query(
          collection(db, "fleetOperators"),
          where("companyId", "==", companyId)
        )
      );
      if (tripsSnapshots.size > 0) {
        const entries = [{ id: null, fullName: "Unassigned" }];
        tripsSnapshots.forEach((doc) =>
          entries.push({ id: doc.id, ...doc.data() })
        );
        setFleetOperators(entries);
      }
    }
    if (isTripsEnabled && !isOperatorsFetched) {
      fetchTrips();
    }
  }, [isTripsEnabled]);

  const handleAdminPromotion = (uid, name) => {
    setLoading(true);
    upgradeToAdmin(uid)
      .then(() => {
        setMessage(
          `Successfully promoted ${name} to 'Admin'. It may take up to a minute for permissions to be set.`
        );
        updateCallback();
      })
      .catch(() => {
        setErrorMessage("Promotion failed. Please try again.");
      }).finally(() => {
        setLoading(false);
      })
  };

  const handleUserDemotion = (uid, name) => {
    setLoading(true);
    downgradeToUser(uid)
      .then(() => {
        setMessage(
          `Successfully demoted ${name} to 'User'. It may take up to a minute for permissions to be set.`
        );
        updateCallback();
      })
      .catch(() => {
        setErrorMessage("Promotion failed. Please try again.");
      }).finally(() => {
        setLoading(false);
      })
  };

  const handleUserAssociation = (userElement, fleetOperatorId) => {
    setLoading(true);
    associateUserWithFleetOperator(userElement.uid, fleetOperatorId)
      .then(() => {
        updateCallback();
        setMessage("Successfully associated user with fleet operator.");
      })
      .catch((e) => {
        userElement.fleetOperatorId = null;
        setErrorMessage(`Association failed. ${e.message}`);
      }).finally(() => {
        setLoading(false);
      })
  };
  const rows = elements.map((element) => (
    <Fragment key={element.id}>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element?.firstName} {element?.lastName}</td>
          <td>
            {!element.admin && userId !== element.uid && (
              <Button
                size="sm"
                color="cap-navy"
                onClick={() => {
                  handleAdminPromotion(element.uid, element.firstName);
                }}
              >
                Promote to Admin
              </Button>
            )}
            {element.admin && userId !== element.uid && (
              <Button
                color="cap-navy"
                size="sm"
                variant="outline"
                onClick={() => {
                  handleUserDemotion(element.uid, element.firstName);
                }}
              >
                Demote to User
              </Button>
            )}
          </td>
          {isTripsEnabled && (
            <td>
              <Select
                label="Fleet Operator"
                placeholder="Unassigned"
                value={element.fleetOperatorId}
                onChange={(v) => handleUserAssociation(element, v)}
                data={fleetOperators.map((config) => {
                  return { value: config.id, label: config.fullName };
                })}
              />
            </td>
          )}
        </tr>
      </MediaQuery>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <tr>
          <td>{element?.firstName} {element?.lastName}</td>
          <td>{element?.admin?.toString()}</td>
          <td>
            {!element.admin && userId !== element.uid && (
              <Button
                size="sm"
                color="cap-navy"
                onClick={() => {
                  handleAdminPromotion(element.uid, element.firstName);
                }}
              >
                Promote to Admin
              </Button>
            )}
            {element.admin && userId !== element.uid && (
              <Button
                color="cap-navy"
                size="sm"
                variant="outline"
                onClick={() => {
                  handleUserDemotion(element.uid, element.firstName);
                }}
              >
                Demote to User
              </Button>
            )}
          </td>
          {isTripsEnabled && (
            <td>
              <Select
                label="Fleet Operator"
                placeholder="Unassigned"
                value={element.fleetOperatorId}
                onChange={(v) => handleUserAssociation(element, v)}
                data={fleetOperators.map((config) => {
                  return { value: config.id, label: config.fullName };
                })}
              />
            </td>
          )}
        </tr>
      </MediaQuery>
    </Fragment>
  ));

  return (
    <>
      <Table width={"100%"} mx={0}>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
              {isTripsEnabled && <th>Linked Fleet Operator</th>}
            </tr>
          </thead>
        </MediaQuery>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Admin</th>
              <th>Actions</th>
              {isTripsEnabled && <th>Linked Fleet Operator</th>}
            </tr>
          </thead>
        </MediaQuery>
        <tbody>{rows}</tbody>
      </Table>
      {loading && <Loader style={{ margin: "1em auto", width: "100%" }}></Loader>}
      <div style={{ position: "relative" }}>
        {message && (
          <Notification
            icon={<Check size={18} />}
            color="teal"
            title="Success"
            style={{ position: "fixed", bottom: "20px", right: "20px", width: "500px" }}
            onClose={() => setMessage("")}
          >
            {message}
          </Notification>
        )}
        {errorMessage && (
          <Notification
            icon={<Flag size={18} />}
            color="red"
            title="Error"
            style={{ position: "fixed", bottom: "20px", right: "20px", width: "500px" }}
            onClose={() => setErrorMessage("")}
          >
            {errorMessage}
          </Notification>
        )}
      </div>
    </>
  );
}

export function Users({ companyId, auth }) {
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const [userId, setUserId] = useState(undefined);

  useEffect(() => {
    if (auth?.claims) {
      setUserId(auth?.claims?.user_id);
    }
  }, [auth]);

  const handleResults = (documentSnapshots, setter) => {
    let entries = [];

    documentSnapshots.forEach((doc) =>
      entries.push({ id: doc.id, ...doc.data() })
    );

    setter(entries);
  };

  const buildQuery = (templateName) => {
    return query(
      collection(db, templateName),
      where("companyId", "==", companyId)
    );
  };

  useEffect(() => {
    if (!fetchedUsers && companyId && userId) {
      async function fetchUsers() {
        setFetchedUsers(true);
        setLoading(true);

        const documentSnapshots = await getDocs(buildQuery("users"));

        handleResults(documentSnapshots, setUsers);
        setLoading(false);
      }
      fetchUsers();
    }
  }, [companyId, userId, fetchedUsers]);
  return (
    <>
      <Grid columns={24}>
        {loading ? (
          <Loader style={{ margin: "auto", width: "100%" }}></Loader>
        ) : (
          <>
            {users && users?.length ? (
              <UserTable
                elements={users}
                updateCallback={() => setFetchedUsers(false)}
                userId={userId}
                companyId={companyId}
              />
            ) : null}
          </>
        )}
        <Divider my="sm" />
      </Grid>
      {message && (
        <Notification
          icon={<InfoCircle size={18} />}
          color="cap-blue"
          title="Info"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "500px",
            zIndex: 1000,
          }}
          onClose={() => setMessage("")}
        >
          {message}
        </Notification>
      )}
    </>
  );
}