import React, { useEffect, useState, Fragment } from "react";

import {
  Button,
  Drawer,
  Modal,
  Notification,
  Table,
  MediaQuery,
} from "@mantine/core";

import { AssetEditor } from "../../components/shared/asset-editor";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { TripConfig } from "../../components/listings/configurations/trip-config";
import TripTimeline from "./trip-drawer-details";
import { Plus } from "tabler-icons-react";

export function TripTable({ elements, auth, reloadCallback, companyId }) {
  const newTrip = {
    description: "",
    status: "PLANNED",
    deliveryAddress: "",
    pickupAddress: "",
    fleetUseFleetForRebateClaim: false,
    pickupDate: new Date().toISOString(),
    deliveryDate: new Date().toISOString(),
    notificationEmailAddresses: [],
    externalReference: "",
    requiresPreInspection: false,
    fleetId: "",
    fleetGroupId: "",
    fleetGroupName: "",
    fleetDescription: "",
    fleetRegistration: "",
    fleetDisplayName: "",
    fleetOdoOrHourBased: "",
    fleetOperatorId: "",
    fleetOperatorNameAndSurname: "",
    fleetOperatorEmployeeCode: "",
    fleetOperatorIdentification: "",
    companyId: companyId,
    isTrackingActive: false,
    notes: "",
    creatorEmail: auth?.claims?.email,
    creatorUid: auth?.claims?.user_id,
    locationSnapshots: [],
    capturedTripArrive: [],
    capturedTripPreInspection: [],
    capturedTripDepart: [],
    capturedTripCheckPoint: [],
    capturedTripComplete: [],
    capturedTripDelay: [],
  };
  const [editedElement, setEditedElement] = useState(undefined);

  const [isFetchedDependencies, setIsFetchedDependencies] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [fleetOperators, setfleetOperators] = useState([]);
  const [vehicleGroups, setVehicleGroups] = useState([]);
  const [message, setMessage] = useState(undefined);
  const [messageColor, setMessageColor] = useState("green");
  const [tripDrawerOpen, setTripdrawerOpen] = useState(false);
  const [viewedTrip, setViewedTrip] = useState(undefined);

  const handleEdit = () => {
    setIsFetchedDependencies(false);
  };

  const handleSave = async (updatedTrip, isDelete) => {
    if (isDelete) {
      reloadCallback(updatedTrip, true);
      setMessageColor("green");
      setMessage("Successfully deleted!");
      return;
    }
    const vehicle = vehicles.find((v) => v.id === updatedTrip.fleetId);
    const fleetOperator = fleetOperators.find(
      (d) => d.id === updatedTrip.fleetOperatorId
    );
    let group;
    if (vehicle) {
      group = vehicleGroups.find((g) => g.id === vehicle.group);
    }
    // if the current trip is planned but has a fleetOperator it becomes allocated
    // otherwise just keep the existing status
    if (editedElement.status === "PLANNED" && updatedTrip.fleetOperatorId) {
      updatedTrip.status = "ALLOCATED";
    }
    const tripToSave = {
      ...editedElement,
      ...updatedTrip,
      fleetGroupId: group?.id || "none",
      fleetGroupName: group?.name || "none",
      fleetId: vehicle?.id || "unassigned",
      fleetDisplayName: vehicle?.name || "unassigned",
      fleetDescription: vehicle?.description || "unassigned",
      fleetRegistration: vehicle?.registrationNumber || "unassigned",
      fleetOdoOrHourBased: vehicle?.measurementUnit || "unassigned",
      fleetOperatorNameAndSurname: fleetOperator?.fullName || "unassigned",
      fleetOperatorId: fleetOperator?.id || "unassigned",
      fleetOperatorEmployeeCode: fleetOperator?.employeeCode || "unassigned",
      fleetOperatorIdentification:
        fleetOperator?.identification || "unassigned",
    };
    const pickupTimestamp = Timestamp.fromDate(new Date(tripToSave.pickupDate));
    tripToSave.pickupTimestamp = pickupTimestamp;
    const deliveryTimestamp = Timestamp.fromDate(new Date(tripToSave.deliveryDate));
    tripToSave.deliveryTimestamp = deliveryTimestamp;
    try {
      // handle save here
      if (tripToSave.id) {
        // save
        const docRef = doc(db, "trips", tripToSave.id);
        await updateDoc(docRef, tripToSave);
        setMessageColor("green");
        setMessage("Successfully updated!");
        reloadCallback(tripToSave, false);
      } else {
        // create
        const docRef = await addDoc(collection(db, "trips"), tripToSave);
        await updateDoc(docRef, { id: docRef.id });
        setMessageColor("green");
        setMessage("Successfully created!");
        reloadCallback({...tripToSave, id: docRef.id}, false);
      }
    } catch (error) {
      console.log(error);
      setMessageColor("red");
      setMessage("Save unsuccessful.", error.toString());
    }
  };

  useEffect(() => {
    async function fetchDeps() {
      if (!isFetchedDependencies) {
        setIsFetchedDependencies(true);
        const fleetSnapshots = await getDocs(
          query(collection(db, "fleet"), where("companyId", "==", companyId))
        );
        if (fleetSnapshots.size > 0) {
          const entries = [];
          fleetSnapshots.forEach((doc) =>
            entries.push({ id: doc.id, ...doc.data() })
          );
          entries.unshift({ id: "", name: "No assignment" });
          setVehicles(entries);
        }

        const fleetOperatorSnapshots = await getDocs(
          query(
            collection(db, "fleetOperators"),
            where("companyId", "==", companyId)
          )
        );
        if (fleetOperatorSnapshots.size > 0) {
          const fleetOperatorEntries = [];
          fleetOperatorSnapshots.forEach((doc) =>
            fleetOperatorEntries.push({ id: doc.id, ...doc.data() })
          );
          fleetOperatorEntries.unshift({ id: "", fullName: "No assignment" });
          setfleetOperators(fleetOperatorEntries);
        }

        const fleetGroupSnapshots = await getDocs(
          query(
            collection(db, "fleetGroups"),
            where("companyId", "==", companyId)
          )
        );
        if (fleetGroupSnapshots.size > 0) {
          const groupEntries = [];
          fleetGroupSnapshots.forEach((doc) =>
            groupEntries.push({ id: doc.id, ...doc.data() })
          );
          setVehicleGroups(groupEntries);
        }
      }
    }
    fetchDeps();
  }, [isFetchedDependencies]);

  const viewElement = (element) => {
    setViewedTrip(element);
    setTripdrawerOpen(true);
  };

  const closeEdit = () => setEditedElement(undefined);
  const EditButton = ({ element }) => (
    <Button
      color={"cap-navy"}
      variant="outline"
      onClick={(e) => {
        e.stopPropagation();
        setEditedElement(element);
        handleEdit();
      }}
    >
      Edit
    </Button>
  );
  const rows = elements.map((element) => (
    <Fragment key={element.id}>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <tr
          onClick={() => viewElement(element)}
          style={{ cursor: "pointer" }}
          className="row"
        >
          <td>{element.description}</td>
          <td>{element.status}</td>
          <td>{element.pickupAddress}</td>
          <td>{element.deliveryAddress}</td>
          <td>{new Date(element.pickupDate).toDateString()}</td>
          <td>{element.fleetOperatorNameAndSurname}</td>
          <td>
            {element.tripToken ? (
              <a
                href={`https://captivatrip.web.app/?tripToken=${element.tripToken}`}
                target="_blank"
              >
                Tracking page
              </a>
            ) : (
              "Not started"
            )}
          </td>
          <td>
            <EditButton element={element} />
          </td>
        </tr>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <tr
          onClick={() => viewElement(element)}
          style={{ cursor: "pointer" }}
          className="row"
        >
          <td>{element.description}</td>
          <td>{element.pickupAddress}</td>
          <td>
            <EditButton element={element} />
          </td>
        </tr>
      </MediaQuery>
    </Fragment>
  ));

  return (
    <>
      <Table width={"100%"}>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Status</th>
              <th>Pickup address</th>
              <th>Delivery address</th>
              <th>Pickup date</th>
              <th>fleetOperator</th>
              <th>Tracking</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <thead>
            <tr>
              <th>Description</th>
              <th>Pickup address</th>
              <th>Actions</th>
            </tr>
          </thead>
        </MediaQuery>
        <tbody>{rows}</tbody>
      </Table>
      {editedElement && (
        <Modal opened onClose={closeEdit} title="Trip" size={"lg"}>
          <AssetEditor
            asset={editedElement}
            assetConfig={TripConfig}
            collection="trips"
            auth={auth}
            dependencyList={{
              fleetId: [...vehicles],
              fleetOperatorId: [...fleetOperators],
            }}
            dependencyLabel={{ fleetId: "name", fleetOperatorId: "fullName" }}
            useCallbackOnSave
            reloadCallback={(updatedTrip) => {
              if(updatedTrip) {
                handleSave(updatedTrip, false);
              } else {
                handleSave(editedElement, true);
              }
              closeEdit();
            }}
          ></AssetEditor>
        </Modal>
      )}

      <Drawer
        size="800px"
        position="right"
        opened={tripDrawerOpen}
        withCloseButton={false}
        lockScroll={false}
        onClose={() => {
          setViewedTrip(undefined);
          setTripdrawerOpen(false);
        }}
      >
        {viewedTrip && <TripTimeline trip={viewedTrip} key={viewedTrip.id} />}
      </Drawer>
      <Button
        onClick={() => setEditedElement(newTrip)}
        fullWidth
        my="xl"
        color="cap-navy"
        variant="outline"
        leftIcon={<Plus />}
      >
        Create new
      </Button>
      {message && (
        <Notification color={messageColor} onClose={() => setMessage("")}>
          {message}
        </Notification>
      )}
    </>
  );
}