import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { AdvancedSettings, AppShellResponsive, Assets, Dashboard, Login, NotificationConfiguration, Users } from "./screens";

import { auth } from "./firebase";
import { Reports } from "./screens/reports";
import { MantineProvider } from "@mantine/core";
import { ResetPassword } from "./screens/reset-password";
import { AdvancedReports } from "./screens/advanced-reports";
import { Account } from "./components";
import { ConfirmEmail } from "./screens/confirm-email";
import Trips from "./screens/trips";

export function AppRoot() {
  const [user, loading, error] = useAuthState(auth);
  const [authValue, setAuthValue] = useState();
  const [companyId, setCompanyId] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const fetchClaim = async () => {
    if(auth.currentUser) {
      auth.currentUser
        .getIdTokenResult()
        .then((idTokenResult) => {
          setAuthValue(idTokenResult);
          setCompanyId(idTokenResult.claims.companyId)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user && location.pathname !== "/reset" && location.pathname !== "/verify") return navigate("/");
    if (error) console.log(error);
    
    fetchClaim();
  }, [user, loading, error, navigate]);

  return (
    <MantineProvider
      withNormalizeCSS 
      withGlobalStyles
      theme={{
        colors: {
          "cap-navy": [
            "#434872",
            "#373E6D",
            "#2C3369",
            "#212967",
            "#162065",
            "#0B1665",
            "#000C66",
            "#091253",
            "#0F1544",
            "#121738",
            "#14172F",
            "#141728",
            "#141622",
          ],
          "cap-purple": [
            "#F3F1F8",
            "#D8D1EC",
            "#BCAFE5",
            "#A18AE5",
            "#8361ED",
            "#6331FF",
            "#582AE7",
            "#532CCA",
            "#5236A8",
            "#4F3B8D",
            "#4B3C77",
            "#463C66",
          ],
          "cap-blue": [
            "#E0E4F4",
            "#BBC5EF",
            "#92A5F1",
            "#6482FC",
            "#5471E9",
            "#4965D4",
            "#425BBF",
            "#4658A1",
            "#475489",
            "#464F75",
          ],
          "cap-orange": [
            "#EAE0DA",
            "#DEC9BD",
            "#D7B39F",
            "#D59E7E",
            "#DB895A",
            "#E87330",
            "#FF5D00",
            "#CF5A17",
            "#A95525",
            "#8D502D",
            "#764A31",
            "#644533",
            "#563F32",
          ],
          "cap-yellow": [
            "#FDFDFB",
            "#F2EDD8",
            "#EEE2B2",
            "#F2DD89",
            "#FFDE59",
            "#EBCB4B",
            "#D5B841",
            "#BEA43D",
            "#9F8D43",
            "#877A44",
            "#736A43",
          ],
          "cap-green": [
            "#005a00",
            "#008000",
            "#bfffbf",
            "#80ff80",
            "#005a00",
            "#005a00",
            "#005a00",
            "#005a00",
            "#005a00",
            "#005a00",
            "#005a00",
          ],
        },
        // colors: {
        //   "navy":["#000c66"],
        //   "purple":["#6331ff"],
        //   "blue":["#6482fc"],
        //   "orange":["#ff5d00"],
        //   "yellow":["#ffde59"],
        // }
      }}
    >
      <AppShellResponsive loading={loading} user={user}>
        <Routes>
          {/* <Route path="/"></Route> */}
          <Route exact path="/" element={<Login />} />
          <Route
            path="/reset"
            element={<ResetPassword />}
          />
          <Route
            path="/verify"
            element={<ConfirmEmail />}
          />
          <Route path="/dashboard" element={<></>} />
          <Route
            path="/dashboard/settings"
            element={<Dashboard auth={authValue} />}
          />
          <Route
            path="/dashboard/trips"
            element={<Trips auth={authValue} companyId={companyId}/>}
          />
          <Route
            path="/dashboard/account"
            element={<Account auth={authValue} />}
          />
          <Route
            path="/dashboard/users"
            element={<Users auth={authValue} companyId={companyId} />}
          />
          <Route
            path="/dashboard/advanced-settings"
            element={<AdvancedSettings auth={authValue} companyId={companyId} />}
          />
          <Route
            path="/dashboard/reports"
            element={<Reports auth={authValue} companyId={companyId} />}
          />
          <Route
            path="/dashboard/advanced-reports"
            element={<AdvancedReports auth={authValue} companyId={companyId} />}
          />
          <Route
            path="/dashboard/assets"
            element={<Assets auth={authValue} companyId={companyId}/>}
          />
          <Route
            path="/dashboard/notifications"
            element={<NotificationConfiguration companyId={companyId} />}
          />
          <Route
            path="/dashboard/:templateName"
            element={<Dashboard auth={authValue} />}
          />
        </Routes>
      </AppShellResponsive>
    </MantineProvider>
  );
}
